import React from 'react'
import gql from 'graphql-tag'
import { compose, graphql } from 'react-apollo'

class OptionZindexForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault()
    const value = parseInt(this.input.value, null)
    this.props.setZindex(value)
  }

  render() {
    if (this.props.data.loading) return null

    return (
      <form>
        <label>
          z-index:{' '}
          <input
            type="number"
            ref={i => {
              this.input = i
            }}
            defaultValue={this.props.data.option.z}
          />
        </label>{' '}
        <button onClick={this.handleSubmit}>save</button>
      </form>
    )
  }
}

const WithOptionZindex = graphql(
  gql`
    query OptionZindexForm($id: ID!) {
      option(id: $id) {
        z
      }
    }
  `,
  {
    options: props => ({ variables: { id: props.optionId } })
  }
)

const WithUpdateZindex = graphql(
  gql`
    mutation SetZindex($option_id: ID!, $z: Int!) {
      setZindex(option_id: $option_id, z: $z) {
        id
        z
      }
    }
  `,
  {
    props: ({ mutate, ownProps: props }) => ({
      setZindex: z =>
        mutate({
          variables: {
            option_id: props.optionId,
            z
          }
        })
    })
  }
)

export default compose(WithOptionZindex, WithUpdateZindex)(OptionZindexForm)
