import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import DealerEditor from './DealerEditor'
import { WithLoadingSpinner } from '../../components/LoadingSpinner'

export default class NewDealerScreen extends React.Component {
  render() {
    return (
      <div
        style={{
          maxWidth: 600,
          margin: '0 auto'
        }}
      >
        <h1 className="landmarklet">Add New Dealer</h1>
        <DealerEditor />
      </div>
    )
  }
}
