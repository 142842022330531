import * as R from 'ramda'

const STORAGE_KEY = 'bygc-admin'

const getPersistentState = R.compose(
  JSON.stringify,
  R.dissocPath(['auth', 'loginError']),
  R.omit(['apollo'])
)

export const persist = state => {
  window.localStorage.setItem(STORAGE_KEY, getPersistentState(state))
}

export const retrieve = () => {
  const stored = window.localStorage.getItem(STORAGE_KEY)

  try {
    return JSON.parse(stored) || undefined
  } catch (e) {
    console.warn(e)
  }
}
