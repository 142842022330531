import React from 'react'
import { sortBy, get } from 'lodash'
import imageExists from 'image-exists'
import styled from 'styled-components'
import CustomSwitch from '../components/Molecules/TableItems/CustomSwitch'

import {
  WithAssignOptions,
  WithUnassignOptions,
  UpdatePresetValue
} from '../components/OptionChooser'

import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'

import LoadingSpinner, { ErrorDisplay } from '../components/LoadingSpinner'

import { getURLS } from '../utils'

const StyledItemAttrDiv = styled.div`
  display: flex;

  span input {
    width: 75%;
  }
`

const moreBusy = state => {
  return {
    busy: state.busy + 1
  }
}

const lessBusy = state => {
  return {
    busy: state.busy - 1
  }
}

const tryToLoadImage = src => {
  return new Promise(resolve => {
    imageExists(src, exists => (exists ? resolve(src) : resolve(null)))
  })
}

const WithBuilders = graphql(
  gql`
    query OptionsTable($id: ID!) {
      user(id: $id) {
        id
        builders {
          guid
          cart_model {
            id
            brand
            model
            available_options {
              id
              name
              label
              parent {
                id
                name
              }
            }
          }
          options {
            edges {
              price
              node {
                id
                name
                label
              }
              isPreset
            }
          }
        }
      }
      options(root: true) {
        id
        name
        label

        children {
          id
          name
          label
        }
      }
    }
  `,
  {
    options: props => ({ variables: { id: props.userId } })
  }
)

class OptionSelectionView extends React.Component {
  state = {
    busy: 0,
    openGroupId: null
  }

  isBusy() {
    return this.state.busy > 0
  }

  async handleCheckBox(isEnabled, userBuilder, optionChildren) {
    if (isEnabled) {
      this.setState(moreBusy)
      await this.props.unassignOptions(userBuilder.guid, [optionChildren.id])
      this.setState(lessBusy)
    } else {
      this.setState(moreBusy)
      await this.props.assignOptions(userBuilder.guid, [
        { option_id: optionChildren.id, price: 0 }
      ])
      this.setState(lessBusy)
    }
  }

  handlePreset = async (isPreset, userBuilder, optionChildren) => {
    this.setState(moreBusy)
    await this.props.handlePreset(userBuilder.guid, [
      { option_id: optionChildren.id, isPreset: isPreset }
    ])
    this.setState(lessBusy)
  }

  render() {
    const busy = this.isBusy()
    const {
      data: { loading, error, user, options }
    } = this.props
    if (loading) return <LoadingSpinner />
    if (error) return <ErrorDisplay message={error.message} />
    const builders = sortBy(
      get(user, 'builders'),
      b => `${b.cart_model.brand} ${b.cart_model.model}`
    )
    return (
      <div
        style={{
          background: 'white',
          padding: '1rem',
          marginTop: '40px'
        }}
      >
        <table className="normal" style={{ tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th className="sticky-th" />
              {builders.map((b, index) => (
                <th
                  key={b.cart_model ? b.cart_model.id : `key_${index}`}
                  className="sticky-th"
                >
                  <b>
                    {b.cart_model.brand} {b.cart_model.model}
                  </b>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {options.map(o => {
              return (
                <React.Fragment key={o.id}>
                  <tr
                    style={{
                      background: '#eee',
                      cursor:
                        this.state.openGroupId === o.id ? 'initial' : 'pointer'
                    }}
                    onClick={() => this.setState({ openGroupId: o.id })}
                  >
                    <td>
                      <button
                        style={{
                          cursor: 'pointer',
                          marginLeft: 10,
                          border: 0,
                          background: 'none',
                          fontSize: '1rem',
                          padding: '0 10px'
                        }}
                        onClick={() => this.setState({ openGroupId: o.id })}
                      >
                        <span
                          style={
                            this.state.openGroupId === o.id
                              ? {
                                  fontSize: '1rem',
                                  marginRight: 4,
                                  marginLeft: -3
                                }
                              : {
                                  fontSize: '0.8rem',
                                  marginRight: 4
                                }
                          }
                        >
                          {this.state.openGroupId === o.id ? '▼' : '►'}
                        </span>
                        <b>{o.label}</b>
                      </button>
                    </td>
                    {builders.map(b => (
                      <td key={b.cart_model.id} />
                    ))}
                  </tr>
                  {this.state.openGroupId === o.id &&
                    o.children.map(c => (
                      <tr key={c.id}>
                        <td
                          style={{
                            padding: '10px 20px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          {c.label}
                          <ThumbnailPreview
                            group={o.name}
                            option={c.name}
                            builders={builders}
                          />
                        </td>
                        {builders.map(b => {
                          const available = b.cart_model.available_options.find(
                            ao => ao.id === c.id
                          )

                          if (available == null) {
                            return (
                              <td
                                key={b.cart_model.id}
                                style={{
                                  color: '#999',
                                  fontSize: '0.8rem',
                                  fontStyle: 'italic'
                                }}
                              >
                                not available
                              </td>
                            )
                          }

                          const builderOption = b.options.edges.find(
                            e => e.node.id === c.id
                          )
                          const enabled = builderOption != null
                          return (
                            <td key={b.cart_model.id}>
                              <label style={{ cursor: 'pointer' }}>
                                <StyledItemAttrDiv>
                                  <input
                                    type="checkbox"
                                    defaultChecked={builderOption != null}
                                    disabled={busy}
                                    onChange={() =>
                                      this.handleCheckBox(enabled, b, c)
                                    }
                                  />
                                  {enabled ? (
                                    <span style={{ marginLeft: 10 }}>
                                      $
                                      <input
                                        type="text"
                                        defaultValue={builderOption.price}
                                        onKeyDown={e => {
                                          if (
                                            e.keyCode === 13 /* Enter */ ||
                                            e.keyCode === 27 /* Escape */
                                          ) {
                                            e.target.blur()
                                          }
                                        }}
                                        onBlur={async e => {
                                          let price = parseFloat(
                                            e.target.value,
                                            10
                                          )
                                          if (price != price || price < 0) {
                                            price = 0
                                            e.target.value = '0'
                                          }
                                          await this.props.assignOptions(
                                            b.guid,
                                            [{ option_id: c.id, price }]
                                          )
                                        }}
                                      />
                                    </span>
                                  ) : (
                                    <i
                                      style={{
                                        color: '#999',
                                        marginLeft: 10,
                                        fontSize: '0.8rem'
                                      }}
                                    >
                                      click to enable
                                    </i>
                                  )}
                                </StyledItemAttrDiv>
                                {enabled && (
                                  <CustomSwitch
                                    checkValue={builderOption.isPreset}
                                    switchChange={isPreset =>
                                      this.handlePreset(isPreset, b, c)
                                    }
                                  />
                                )}
                              </label>
                            </td>
                          )
                        })}
                      </tr>
                    ))}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
}

class ThumbnailPreview extends React.Component {
  state = {
    hover: false,
    url: null
  }

  static dimensions = {
    height: 16,
    width: 25
  }

  async componentDidMount() {
    const { builders, group, option } = this.props

    let url = await Promise.race(
      builders.map(({ cart_model: { brand, model } }) =>
        tryToLoadImage(getURLS(brand, model, group, option).thumb)
      )
    )

    if (url != null) {
      this.setState({ url })
    }
  }

  render() {
    let { hover, url } = this.state
    return (
      <div
        style={{ cursor: 'zoom-in' }}
        {...ThumbnailPreview.dimensions}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        {url != null ? (
          <img
            src={url}
            {...ThumbnailPreview.dimensions}
            style={{
              pointerEvents: 'none',
              transform: hover ? 'scale(4)' : 'none'
            }}
          />
        ) : null}
      </div>
    )
  }
}

export default compose(
  WithAssignOptions,
  WithUnassignOptions,
  UpdatePresetValue,
  WithBuilders
)(OptionSelectionView)
