import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'

import UserHome from '../UserHome'
import { WithLoadingSpinner } from '../../components/LoadingSpinner'

const query = gql`
  query DealerDetail($id: ID!) {
    user(id: $id) {
      id
      name
      email
      business_name
      location

      builders {
        guid
      }
    }
  }
`

const DealerDetail = props => (
  <WithLoadingSpinner {...props}>
    {({ data: { user } }) => (
      <div>
        <h1>{user.business_name}</h1>
        <h2>{user.name}</h2>
        <h3>{user.location}</h3>
        <h4>{user.email}</h4>
        <div>
          <Link to={`/dealers/edit/${user.id}`}>Edit Info</Link>
        </div>
        {user.builders.length > 0 && (
          <div>
            <Link to={`/preview/${user.builders[0].guid}`}>
              Preview + Embed
            </Link>
          </div>
        )}
        <div>
          <Link to={`/dealers/${user.id}/stats`}>Stats Page</Link>
        </div>
        <UserHome userId={user.id} optionsURL={`/dealers/${user.id}/options`} />
      </div>
    )}
  </WithLoadingSpinner>
)

export default graphql(query, {
  options: props => ({
    variables: { id: props.match.params.id }
  })
})(DealerDetail)
