import { API_ROOT } from '../config'

export const authenticate = (email, password) => {
  return dispatch => {
    dispatch({ type: 'authenticate:request' })

    window
      .fetch(API_ROOT + '/oauth/token', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: email,
          password,
          grant_type: 'password'
        })
      })
      .then(res => {
        if (res.ok) return res.json()
        throw new Error(res.statusText)
      })
      .then(
        token => dispatch({ type: 'authenticate:success', payload: { token } }),
        err => dispatch({ type: 'authenticate:failure', error: err.message })
      )
  }
}

export const deauthenticate = () => {
  return (dispatch, _, { apolloClient, persistor }) => {
    persistor.remove()
    persistor.purge().then(() => {
      dispatch({ type: 'deauthenticate' })
      apolloClient.resetStore()
      window.localStorage.clear()
      window.location.href = '/'
    })
  }
}

export const resetPassword = email => {
  return dispatch => {
    dispatch({ type: 'password:reset' })

    window.fetch(API_ROOT + '/reset_password/', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    })
  }
}

export const fetchAnalytics = email => {
  return dispatch => {
    window.fetch(API_ROOT + '/query_ga/9f2e2318-6f02-413d-ab3c-500ad05685f5', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email })
    })
  }
}
