export const confirm = (fn, ...args) =>
  window.confirm('Are you sure?') && fn(...args)

export const pluralize = (count, singular, plural = singular + 's') =>
  count === 1 ? singular : plural

export const imageBase = 'https://s3.amazonaws.com/build-your-golf-cart'
export const getURLS = (brand, model, group, option) => {
  return {
    base: `${imageBase}/${brand}/${model}/base.png`,
    mod: `${imageBase}/${brand}/${model}/${group}/${option}.png`,
    thumb: `${imageBase}/${brand}/${model}/${group}/${option}_thumb.png`
  }
}

// like http://package.elm-lang.org/packages/elm-lang/core/latest/List#partition
export const partition = (pred, coll) =>
  coll.reduce(
    (acc, x) => {
      const match = pred(x)
      acc[match ? 0 : 1].push(x)
      return acc
    },
    [[], []]
  )
