export const getAccessToken = state =>
  state.auth && state.auth.token && state.auth.token.access_token

const auth = (state = {}, action) => {
  switch (action.type) {
    case 'authenticate:success':
      return action.payload
    case 'authenticate:failure':
      return { loginError: action.error }
    case 'deauthenticate':
      return {}
    default:
      return state
  }
}

export default {
  auth
}
