import React from 'react'
import { Field, Formik } from 'formik'

import Button from '../../../components/Button'

const defaultValues = {
  id: '',
  allowed_domain: '',
  business_name: '',
  email: '',
  cc_emails: '',
  location: '',
  name: '',
  active: true
}

const requiredFields = ['name', 'email']

const styles = {
  label: {
    padding: '.25rem .5rem',
    textShadow: '1px 1px #27723c'
  },
  labelError: {
    color: 'red',
    textShadow: 'none'
  },
  input: {
    padding: '.25rem .5rem',
    borderRadius: 2,
    border: '1px solid #27723c',
    fontSize: '1.25rem',
    width: '100%'
  },
  check: {
    padding: '.25rem .5rem',
    borderRadius: 2,
    border: '1px solid #27723c',
    width: '100%',
    marginLeft: '10px'
  }
}

class DealerForm extends React.Component {
  static defaultProps = {
    onSubmit: values => {
      console.log('No onSubmit prop passed to form. Values submitted:', values)
    }
  }

  field({ errors, touched }, name, label) {
    return (
      <div className="landmarklet">
        <label>
          <div
            style={{
              ...styles.label,
              ...(errors[name] != null && touched[name]
                ? styles.labelError
                : {})
            }}
          >
            {label}
            {requiredFields.includes(name) && '*'}
          </div>
          <Field
            name={name}
            style={styles.input}
            disabled={this.props.disabled}
          />
        </label>
      </div>
    )
  }

  check({ errors, touched, values }, name, label) {
    return (
      <div className="landmarklet">
        <label>
          <div
            style={{
              ...styles.label,
              ...(errors[name] != null && touched[name]
                ? styles.labelError
                : {})
            }}
          >
            {label}
            {requiredFields.includes(name) && '*'}
          </div>
          <Field
            name={name}
            type="checkbox"
            value={values.active}
            checked={values.active}
            style={styles.checkbox}
            disabled={this.props.disabled}
          />
        </label>
      </div>
    )
  }

  validate = values => {
    return requiredFields.reduce((errors, field) => {
      if (values[field].trim() === '') {
        errors[field] = 'Required'
      }
      return errors
    }, {})
  }

  render() {
    return (
      <Formik
        initialValues={this.props.initialValues || defaultValues}
        onSubmit={async (values, formik) => {
          await this.props.onSubmit(values)
          formik.setSubmitting(false)
        }}
        validate={this.validate}
        render={formikProps => (
          <form onSubmit={formikProps.handleSubmit}>
            {this.field(formikProps, 'name', 'Name')}
            {this.field(formikProps, 'email', 'Email')}
            {this.field(formikProps, 'cc_emails', 'CC Emails')}
            {this.field(formikProps, 'business_name', 'Business Name')}
            {this.field(formikProps, 'allowed_domain', 'Allowed Domain')}
            {this.field(formikProps, 'location', 'Location')}
            {this.check(formikProps, 'active', 'Active')}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button primary>Save</Button>
            </div>
          </form>
        )}
      />
    )
  }
}

export default DealerForm
