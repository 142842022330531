import React from 'react'

import './LoadingSpinner.css'

const LoadingSpinner = props => (
  <div className="LoadingSpinner">
    <svg height={50} width={50}>
      <circle cx={25} cy={25} r={24} />
    </svg>
    <img src="/images/logo.png" width={32} height={32} alt="Loading..." />
  </div>
)

export default LoadingSpinner

const wrapperStyle = {
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 20,
  paddingBottom: 20
}

export const ErrorDisplay = props => (
  <div className="wrapper" style={wrapperStyle}>
    <pre>{props.message}</pre>
  </div>
)

export const WithLoadingSpinner = props =>
  props.data.loading ? (
    <div className="wrapper" style={wrapperStyle}>
      <LoadingSpinner />
    </div>
  ) : props.data.error ? (
    console.error(props.data.error) || (
      <ErrorDisplay message={props.data.error.message} />
    )
  ) : (
    props.children(props)
  )
