import React from 'react'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import { Link } from 'react-router-dom'

import { partition } from '../../utils'
import Builder from '../../components/Builder'
import { WithLoadingSpinner } from '../../components/LoadingSpinner'

const query = gql`
  query BuilderList {
    builders {
      guid
      active
      ...builderInfo
    }
  }
`

class BuilderItem extends React.PureComponent {
  render() {
    const { builder } = this.props

    return (
      <div style={{ display: 'flex' }}>
        <Link
          className="anchor-plain"
          to={`/builders/${builder.guid}`}
          style={{
            display: 'inline-flex',
            flex: 1,
            flexDirection: 'column'
          }}
        >
          <Builder {...builder} />
        </Link>
      </div>
    )
  }
}

class BuildersMain extends React.PureComponent {
  render() {
    return (
      <div>
        <p>
          Builder (overview) - This is a preview of what your cart builder will
          look like once embedded in your website. Or use it here within the app
        </p>
        <h1>Builders</h1>
        <WithLoadingSpinner {...this.props}>
          {props => {
            const [active, inactive] = partition(
              b => b.active,
              props.data.builders
            )
            return (
              <div>
                <h2>Active</h2>
                {active.map(builder => (
                  <BuilderItem builder={builder} key={builder.guid} />
                ))}
                <h2>Inactive</h2>
                {inactive.map(builder => (
                  <BuilderItem builder={builder} key={builder.guid} />
                ))}
              </div>
            )
          }}
        </WithLoadingSpinner>
      </div>
    )
  }
}

export default graphql(query, {
  options: { fragments: Builder.fragment }
})(BuildersMain)
