import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import UserSaveTable from '../../components/UserSaveTable'
import { WithLoadingSpinner } from '../../components/LoadingSpinner'

const query = gql`
  query UserSaves($cursor: String) {
    viewer {
      id
      builder {
        guid
        user_saves(first: 10, after: $cursor) {
          edges {
            cursor
            node {
              ...userSaveInfo
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
  ${UserSaveTable.fragment}
`

export default graphql(query, {
  options: { fragments: UserSaveTable.fragment },
  props({ data }) {
    return {
      data,
      toBeginning: () =>
        data.fetchMore({
          query,
          fragments: UserSaveTable.fragment,
          variables: {
            cursor: ''
          },
          updateQuery: (previousResult, { fetchMoreResult }) =>
            fetchMoreResult.data
        }),
      loadMore: () =>
        data.fetchMore({
          query,
          fragments: UserSaveTable.fragment,
          variables: {
            cursor: data.viewer.builder.user_saves.pageInfo.endCursor
          },
          updateQuery: (previousResult, { fetchMoreResult }) => {
            fetchMoreResult.viewer.builder.user_saves.edges = [
              // merge old data with new data
              ...previousResult.viewer.builder.user_saves.edges,
              ...fetchMoreResult.viewer.builder.user_saves.edges
            ]
            return fetchMoreResult
          }
        })
    }
  }
})(props => (
  <div>
    <h1>Leads</h1>
    <p>
      This page allows you to view all your leads that were &lsquo;saved to
      order&rsquo; on your cart builder. Click on the customer's name to see
      their info and the options they saved for their cart build.
    </p>
    <WithLoadingSpinner {...props}>
      {props => (
        <div>
          <UserSaveTable
            userSaves={props.data.viewer.builder.user_saves.edges}
            admin={props.admin}
          />
          <button onClick={props.toBeginning}>most recent</button>
          <button
            onClick={props.loadMore}
            disabled={
              !props.data.viewer.builder.user_saves.pageInfo.hasNextPage
            }
          >
            more
          </button>
        </div>
      )}
    </WithLoadingSpinner>
  </div>
))
