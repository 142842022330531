import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import * as R from 'ramda'
import React, { Component } from 'react'

import LoadingSpinner from './LoadingSpinner'
import Button from './Button'
import { getURLS } from '../utils'

import './OptionChooser.css'

export const WithAssignOptions = graphql(
  gql`
    mutation AssignOptions($builder_id: ID!, $options: [OptionAndPrice]!) {
      assignOptions(builder_id: $builder_id, options: $options) {
        options {
          edges {
            price
            node {
              id
              label
              name
            }
            isPreset
          }
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      assignOptions: (builder_id, options) =>
        mutate({
          variables: { builder_id, options },
          updateQueries: {
            OptionsTable: (prev, { mutationResult }) => {
              return {
                ...prev,
                user: {
                  ...prev.user,
                  builders: prev.user.builders.reduce((builders, builder) => {
                    if (builder.guid === builder_id) {
                      builders.push({
                        ...builder,
                        options: mutationResult.data.assignOptions.options
                      })
                    } else {
                      builders.push(builder)
                    }
                    return builders
                  }, [])
                }
              }
            }
          }
        })
    })
  }
)

export const WithUnassignOptions = graphql(
  gql`
    mutation UnassignOptions($builder_id: ID!, $option_ids: [ID]!) {
      unassignOptions(builder_id: $builder_id, option_ids: $option_ids) {
        options {
          edges {
            node {
              id
            }
          }
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      unassignOptions: (builder_id, option_ids) =>
        mutate({
          variables: { builder_id, option_ids },
          updateQueries: {
            OptionsTable: (prev, { mutationResult }) => {
              return {
                ...prev,
                user: {
                  ...prev.user,
                  builders: prev.user.builders.map(builder => {
                    return builder.guid === builder_id
                      ? {
                          ...builder,
                          options: {
                            ...builder.options,
                            edges: builder.options.edges.filter(edge => {
                              return !option_ids.includes(edge.node.id)
                            })
                          }
                        }
                      : builder
                  })
                }
              }
            }
          }
        })
    })
  }
)

export const UpdatePresetValue = graphql(
  gql`
    mutation HandlePreset($builder_id: ID!, $options: [OptionAndPreset]!) {
      handlePreset(builder_id: $builder_id, options: $options) {
        options {
          edges {
            price
            node {
              id
              label
              name
            }
            isPreset
          }
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      handlePreset: (builder_id, options) =>
        mutate({
          variables: { builder_id, options },
          updateQueries: {
            OptionsTable: (prev, { mutationResult }) => {
              return {
                ...prev,
                user: {
                  ...prev.user,
                  builders: prev.user.builders.map(builder => {
                    return builder.guid === builder_id
                      ? {
                          ...builder,
                          options: mutationResult.data.handlePreset.options
                        }
                      : builder
                  })
                }
              }
            }
          }
        })
    })
  }
)

const TabRow = (tabs, active, onSwitch) => (
  <ul className="options-chooser__tabs">
    {tabs.map(t => {
      const isActive = t.id === active
      return (
        <li key={t.id}>
          <button
            className={
              'options-chooser__tabs__btn' + (isActive ? ' active' : '')
            }
            onClick={onSwitch.bind(null, t.id)}
          >
            {t.label}
          </button>
        </li>
      )
    })}
  </ul>
)

const ChildGrid = (cartModel, options, prices, onToggle, onPriceChange) => (
  <div className="child-grid">
    {options.map(o => (
      <div key={o.id} className="child-grid__option">
        <button onClick={onToggle.bind(null, o.id)}>
          <span className={o.selected && 'selected'}>
            <img
              src={
                getURLS(cartModel.brand, cartModel.model, o.parent.name, o.name)
                  .thumb
              }
              alt=""
              height={67}
              width={100}
            />
          </span>
        </button>
        <div>
          {o.label}
          <br />
          <div
            className="price-input"
            style={{ opacity: o.selected ? 1 : 0.4 }}
          >
            <span>$</span>
            <input
              type="text"
              placeholder="0"
              value={prices[o.id] || ''}
              size="6"
              disabled={!o.selected}
              onChange={e => o.selected && onPriceChange(o.id, e.target.value)}
            />
          </div>
        </div>
      </div>
    ))}
  </div>
)

const loadingWrapperStyle = {
  display: 'flex',
  justifyContent: 'center',
  paddingTop: 20,
  paddingBottom: 20
}

class OptionChooser extends Component {
  constructor(props) {
    super(props)

    this.initiallySelectedIds = this.props.builderOptions.map(o => o.node.id)

    this.state = {
      currentTabId: this.props.options[0].id,
      prices: this.props.builderOptions.reduce((map, edge) => {
        map[edge.node.id] = edge.price
        return map
      }, {}),
      selectedOptions: this.initiallySelectedIds,
      saving: false
    }

    this.handleSave = this.handleSave.bind(this)
  }

  selectTab(tab) {
    this.setState({ currentTabId: tab })
  }

  toggleOption(id) {
    this.setState(state => {
      if (state.selectedOptions.includes(id)) {
        return {
          ...state,
          selectedOptions: state.selectedOptions.filter(x => x !== id)
        }
      } else {
        return {
          ...state,
          selectedOptions: state.selectedOptions.concat([id])
        }
      }
    })
  }

  updatePrice(id, price) {
    this.setState({ prices: { ...this.state.prices, [id]: price } })
  }

  handleSave() {
    this.setState({ saving: true })
    // we're essentially doing an upsert on the backend, so we don't have to
    // detect if prices changed.
    const add = this.state.selectedOptions
    // if an option was initially selected and is not selected, remove
    const remove = R.difference(
      this.initiallySelectedIds,
      this.state.selectedOptions
    )

    let tasks = []

    if (add.length > 0) {
      tasks.push(
        this.props.assignOptions(
          this.props.builderId,
          add.map(id => ({
            option_id: id,
            price: parseFloat(this.state.prices[id], 10) || 0
          }))
        )
      )
    }

    if (remove.length > 0) {
      tasks.push(this.props.unassignOptions(this.props.builderId, remove))
    }

    Promise.all(tasks)
      .then(responses => {
        window.location.href = '/'
      })
      .catch(() => this.setState({ saving: false }))
  }

  isSelected(option) {
    return this.state.selectedOptions.includes(option.id)
  }

  render() {
    const { currentTabId } = this.state
    const { options } = this.props

    const childrenOfSelected = this.props.availableOptions
      .filter(o => o.parent.id === currentTabId)
      .map(o => ({ ...o, selected: this.isSelected(o) }))

    if (this.state.saving) {
      return (
        <div style={loadingWrapperStyle}>
          <LoadingSpinner />
        </div>
      )
    }

    return (
      <div>
        <p>
          <strong>Options (overview)</strong> - This page displays all the
          options available for users of your cart builder to customize their
          cart. Options will not be visible to users until you enable them.
        </p>
        <p>
          Clicking on a section name on the left (e.g. Body/Paint, Wheels,
          Seats, etc.) will display all the options that you can enable. Check
          the box next to an option name in the column that corresponds to the
          cart model (e.g. Club Car Precedent, Yamaha EZGO, etc.) to enable it.
          Options will be made live as you enable them.
        </p>
        <p>
          If you wish to display a price for an option, enter it in the box next
          to the option name. Leaving the value as the default $0 will cause the
          price to be hidden on the live cart builder.
        </p>
        <p>Check back often, as we add new options frequently.</p>
        <div className="options-chooser landmark">
          {TabRow(options, currentTabId, tab => {
            this.selectTab(tab)
          })}
          {ChildGrid(
            this.props.cartModel,
            childrenOfSelected,
            this.state.prices,
            id => {
              this.toggleOption(id)
            },
            (id, value) => {
              this.updatePrice(id, value)
            }
          )}
        </div>
        <Button className="options-chooser__save-btn" onClick={this.handleSave}>
          Save
        </Button>
      </div>
    )
  }
}

export default compose(WithAssignOptions, WithUnassignOptions)(OptionChooser)
