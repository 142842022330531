import React from 'react'

function CouponTable({ coupons, openForm }) {
  return [
    <h3 key="coupon-header">All Coupons</h3>,
    <div className="Card table" key="coupon-table" style={{ paddingBottom: 0 }}>
      <div className="th">
        <div className="td">expiration</div>
        <div className="td">code</div>
        <div className="td">percentage</div>
        <div className="td">amount</div>
      </div>
      {coupons.map(c => (
        <div className="tr" onClick={() => openForm(c)} key={c.id}>
          <span className="td">{c.expiration}</span>
          <span className="td">{c.code}</span>
          <span className="td">
            {c.percentage ? `${c.percentage}%` : 'N/A'}
          </span>
          <span className="td">{c.limit || 'N/A'}</span>
        </div>
      ))}
    </div>
  ]
}

export default CouponTable
