import React from 'react'
import { API_ROOT } from '../../config'
import LoadingSpinner from '../../components/LoadingSpinner'

export default class EditCoupon extends React.Component {
  state = {
    coupons: [],
    loading: false,
    error: false,
    formData: {
      percentage: '',
      code: '',
      limit: '',
      expiration: ''
    }
  }

  componentDidMount() {
    if (this.props.coupon) {
      const initialFormData = {
        expiration: this.props.coupon.expiration,
        code: this.props.coupon.code,
        limit: this.props.coupon.limit,
        percentage: this.props.coupon.percentage
      }
      const formData = { ...this.state.formData, ...initialFormData }
      this.setState({ ...this.state, formData: formData })
    }
  }

  updateField(ev) {
    const formData = {
      ...this.state.formData,
      [ev.target.name]: ev.target.value
    }
    this.setState({ ...this.state, formData: formData })
  }

  handleSubmit() {
    this.setState({ loading: true })
    return window
      .fetch(API_ROOT + '/coupons', {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          coupon: this.state.formData,
          id: this.props.coupon.id,
          guid: this.props.guid
        })
      })
      .then(res => {
        if (res.status === 422) {
          console.log('ERRR')
        }
        this.props.closeForm()
        this.props.refresh()
      })
      .catch(_ => {
        this.setState({ error: true, loading: false })
      })
  }

  render() {
    const { formData: { expiration, code, percentage, limit } } = this.state
    const wrapperStyle = {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 20,
      paddingBottom: 20
    }

    const minDate = new Date(Date.now()).toISOString().split('T')[0]

    if (this.state.loading) {
      return (
        <div className="wrapper" style={wrapperStyle}>
          <LoadingSpinner />
        </div>
      )
    }

    return (
      <div>
        <h3>Edit A Coupon</h3>
        <div className="app-form">
          <form
            onSubmit={e => {
              e.preventDefault()
              this.handleSubmit()
            }}
          >
            <p className="form-label">Expiration</p>
            <input
              type="date"
              name="expiration"
              min={minDate}
              value={expiration}
              onChange={e => this.updateField(e)}
            />
            <p className="form-label">Code</p>
            <input
              type="text"
              name="code"
              placeholder="Ex. SAVE20"
              value={code}
              onChange={e => this.updateField(e)}
            />
            <p className="form-label">Percentage</p>
            <input
              type="number"
              min={0}
              max={99}
              name="percentage"
              value={percentage}
              onChange={e => this.updateField(e)}
            />
            <p className="form-label">Amount</p>
            <input
              type="number"
              name="limit"
              value={limit}
              onChange={e => this.updateField(e)}
            />
            <input type="submit" value="Save" />
            <input
              type="cancel"
              onClick={this.props.closeForm}
              value="Cancel"
            />
          </form>
        </div>
      </div>
    )
  }
}
