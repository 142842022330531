import React from 'react'

import EmbedCode, { getEmbedURL } from '../components/EmbedCode'

import { HeadingWrapper } from './UserHome'

function getAppURL(guid) {
  return process.env.NODE_ENV !== 'production'
    ? `http://localhost:3002/${guid}`
    : `https://app.buildyourgolfcart.com/${guid}`
}

function AppLinkCode({ guid }) {
  return (
    <div className="pre">
      {`<a href="${getAppURL(guid)}" target="_blank" rel="noreferrer noopener">
        <img src="https://build-your-golf-cart.s3.amazonaws.com/launch_app_badge.png" height="60" width="225"/>
      </a>`}
    </div>
  )
}

export default props => (
  <div className="island">
    <HeadingWrapper>
      <h1>App Link</h1>
    </HeadingWrapper>
    <a href={getAppURL(props.guid)} target="_blank" rel="noreferrer noopener">
      <img
        src="https://build-your-golf-cart.s3.amazonaws.com/launch_app_badge.png"
        height="60"
        width="225"
      />
    </a>
    <p>
      Copy and paste this code into your website to display a link to your golf
      cart builder on your website. If you need help, contact&nbsp;
      <a href="mailto:info@buildyourgolfcart.com" target="_top">
        info@buildyourgolfcart.com
      </a>
      .
    </p>
    <AppLinkCode guid={props.guid} />
    <HeadingWrapper>
      <h1>Embed Code</h1>
    </HeadingWrapper>
    <p>
      Copy and paste this code into your website to show your cart builder on
      your website. If you need help, contact&nbsp;
      <a href="mailto:info@buildyourgolfcart.com" target="_top">
        info@buildyourgolfcart.com
      </a>
      .
    </p>
    <EmbedCode guid={props.guid} />
    <HeadingWrapper>
      <h1>Live Preview</h1>
    </HeadingWrapper>
    <p>
      This is a live preview of your cart builder with all your currently
      enabled options. It will look like this when embedded in your website.
    </p>
    <iframe
      title="Builder Preview"
      src={getEmbedURL(props.guid) + '?secretbackdoor=1'}
      width="100%"
      style={{ border: 'none' }}
      height="800"
    />
    {props.user && !props.user.active && (
      <p>
        Your account has been deactivated. Please contact&nbsp;
        <a href="mailto:info@buildyourgolfcart.com" target="_top">
          info@buildyourgolfcart.com
        </a>{' '}
        for more information.
      </p>
    )}
  </div>
)
