import React from 'react'
import { Link } from 'react-router-dom'
import gql from 'graphql-tag'
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now'
import { calculateTotal, formatter } from '../screens/UserSaves/helpers'

const fragment = gql`
  fragment userSaveInfo on UserSave {
    id
    name
    phone
    options
    created_at
    email
    user {
      business_name
    }
    builder {
      show_prices
    }
  }
`

const UserSaveTable = ({ userSaves, data, admin }) => {
  return (
    <div className="Card table">
      <div className="th">
        <div className="td">created</div>
        <div className="td">name</div>
        <div className="td">phone</div>
        <div className="td">email</div>
        <div className="td">description</div>
        {admin && <div className="td">dealer</div>}
        <div className="td">total</div>
        <div className="td" />
      </div>
      {userSaves.map(({ node: save }, n) => {
        const options = JSON.parse(save.options) || []
        const option = options[0] || {}
        const { description } = option || {}

        return (
          <Link
            className="tr anchor-plain"
            key={save.id}
            to={`/leads/${save.id}`}
          >
            <span className="td">
              {distanceInWordsToNow(save.created_at)} ago
            </span>
            <span className="td">{save.name}</span>
            <span className="td">{save.phone || 'Number not provided'}</span>
            <span className="td">{save.email}</span>
            <span className="td">{description}</span>
            {admin && <span>{save.user.business_name}</span>}
            {save.builder.show_prices ? (
              <span>
                {formatter.format(calculateTotal(JSON.parse(save.options)))}
              </span>
            ) : (
              <span>-</span>
            )}
            <span id="view-lead" className="td">
              <Link key={save.id} to={`/leads/${save.id}`}>
                {' '}
                <button className="view-button">view</button>
              </Link>
            </span>
          </Link>
        )
      })}
    </div>
  )
}

UserSaveTable.fragment = fragment

export default UserSaveTable
