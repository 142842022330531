import React from 'react'

export default ({ errors, hasError = false }) => {
  if (!hasError) return null
  const formatErrors = errors => {
    return Object.keys(errors).map(k => {
      const msg = `${k}: ${errors[k].join('. ')} `
      return <li>{msg}</li>
    })
  }
  return (
    <div>
      <h3>Please fix the following errors:</h3>
      {formatErrors(errors)}
    </div>
  )
}
