import React from 'react'
import * as R from 'ramda'
import distanceInWordsToNow from 'date-fns/distance_in_words_to_now'
import gql from 'graphql-tag'
import styled from 'styled-components'

const BusinessName = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`

const OwnerName = styled.div`
  font-size: 1.25rem;
`

const LastSave = styled.div`
  color: #808080;
  font-style: italic;
`

const fragment = gql`
  fragment builderInfo on Builder {
    user {
      business_name
      name
      location
    }
    user_saves(first: 1) {
      edges {
        node {
          created_at
        }
      }
    }
  }
`

const Builder = props => {
  const lastSave = R.path(
    ['user_saves', 'edges', 0, 'node', 'created_at'],
    props
  )
  return (
    <div className="Card Builder island gutter">
      <div className="flex1">
        <BusinessName>{props.user.business_name}</BusinessName>
        <OwnerName>{props.user.name}</OwnerName>
        <div>{props.user.location}</div>
        <LastSave>
          {lastSave
            ? `Last save: ${distanceInWordsToNow(lastSave)} ago`
            : 'No saves'}
        </LastSave>
      </div>
    </div>
  )
}

Builder.fragment = fragment

export default Builder
