import React from 'react'
import gql from 'graphql-tag'
import { compose, graphql } from 'react-apollo'
import * as R from 'ramda'

class OptionAvailabilityToggler extends React.Component {
  state = {
    busy: false
  }

  handleToggle = e => {
    this.setState({ busy: true })
    const toggle = e.target.checked
      ? this.props.makeAvailable
      : this.props.makeUnavailable
    toggle().then(() => {
      this.setState({ busy: false })
    })
  }

  render() {
    if (this.props.data.loading)
      return (
        <div style={{ opacity: 0.6 }}>
          <input type="checkbox" disabled key="preview" />
          Available
        </div>
      )

    const { cart_model } = this.props.data
    const isAvailable =
      cart_model.available_options.find(o => o.id === this.props.optionId) !=
      null
    const name = `${cart_model.brand} ${cart_model.model}`

    return (
      <div style={{ opacity: this.state.busy ? 0.4 : undefined }}>
        <input
          type="checkbox"
          checked={isAvailable}
          disabled={this.state.busy}
          onChange={this.handleToggle}
        />
        Available for {name}
      </div>
    )
  }
}

const WithAvailableOptions = graphql(
  gql`
    query OptionAvailabilityToggler($id: ID!) {
      cart_model(id: $id) {
        id
        brand
        model
        available_options {
          id
        }
      }
    }
  `,
  {
    options: props => ({ variables: { id: props.cartModelId } })
  }
)
const updateAvailableOptions = (previousQueryResult, { mutationResult }) => {
  const mutation =
    mutationResult.data.makeOptionAvailable ||
    mutationResult.data.makeOptionUnavailable
  const targetId = mutation.id
  const giveNewOptions = R.assoc(
    'available_options',
    mutation.available_options
  )
  if (previousQueryResult.cart_model) {
    return R.objOf('cart_model', giveNewOptions(previousQueryResult))
  } else if (previousQueryResult.cart_models) {
    return R.assoc(
      'cart_models',
      previousQueryResult.cart_models.map(model => {
        if (model.id === targetId) {
          return giveNewOptions(model)
        } else {
          return model
        }
      }),
      previousQueryResult
    )
  }
}

const WithMakeAvailable = graphql(
  gql`
    mutation MakeAvailable($cart_model_id: ID!, $option_id: ID!) {
      makeOptionAvailable(
        cart_model_id: $cart_model_id
        option_id: $option_id
      ) {
        id
        available_options {
          id
        }
      }
    }
  `,
  {
    props: ({ mutate, ownProps: props }) => ({
      makeAvailable: () =>
        mutate({
          variables: {
            cart_model_id: props.cartModelId,
            option_id: props.optionId
          },
          updateQueries: {
            OptionAvailabilityToggler: updateAvailableOptions,
            OptionsPage: updateAvailableOptions
          }
        })
    })
  }
)

const WithMakeUnavailable = graphql(
  gql`
    mutation MakeUnavailable($cart_model_id: ID!, $option_id: ID!) {
      makeOptionUnavailable(
        cart_model_id: $cart_model_id
        option_id: $option_id
      ) {
        id
        available_options {
          id
        }
      }
    }
  `,
  {
    props: ({ mutate, ownProps: props }) => ({
      makeUnavailable: (cartModel, option) =>
        mutate({
          variables: {
            cart_model_id: props.cartModelId,
            option_id: props.optionId
          },
          updateQueries: {
            OptionAvailabilityToggler: updateAvailableOptions,
            OptionsPage: updateAvailableOptions
          }
        })
    })
  }
)

export default compose(
  WithMakeAvailable,
  WithMakeUnavailable,
  WithAvailableOptions
)(OptionAvailabilityToggler)
