import React from 'react'
import ReactToPrint from 'react-to-print'
import { API_ROOT } from '../../config'
import LoadingSpinner from '../../components/LoadingSpinner'
import Chart from './Chart'

export default class StatsMain extends React.PureComponent {
  state = {
    gaData: {
      monthly: {},
      allTime: {},
      chart: {},
      user_chart: {},
      leads: {},
      leads_monthly: {}
    },
    loading: false,
    error: false
  }

  componentDidMount() {
    this.setState({ loading: true })
    return window
      .fetch(API_ROOT + `/query_ga/${this.props.userId}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(r => r.json())
      .then(json => {
        this.setState({ gaData: json, loading: false })
      })
      .catch(_ => this.setState({ error: true, loading: false }))
  }

  render() {
    const wrapperStyle = {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 20,
      paddingBottom: 20
    }

    const monthlyActiveUsers =
      this.state.gaData.monthly.totals_for_all_results &&
      Number(this.state.gaData.monthly.totals_for_all_results['ga:users'])

    const allActiveUsers =
      this.state.gaData.allTime.totals_for_all_results &&
      Number(this.state.gaData.allTime.totals_for_all_results['ga:users'])

    const monthlyEvents =
      this.state.gaData.monthly.totals_for_all_results &&
      Number(this.state.gaData.monthly.totals_for_all_results['ga:totalEvents'])

    const allEvents =
      this.state.gaData.allTime.totals_for_all_results &&
      Number(this.state.gaData.allTime.totals_for_all_results['ga:totalEvents'])

    const pageViews =
      this.state.gaData.monthly.totals_for_all_results &&
      Number(this.state.gaData.monthly.totals_for_all_results['ga:pageviews'])

    const allTimePageViews =
      this.state.gaData.allTime.totals_for_all_results &&
      Number(this.state.gaData.allTime.totals_for_all_results['ga:pageviews'])

    const mostClickedEvents = this.state.gaData.events
    const mostClickedMonthlyEvents = this.state.gaData.events_monthly
    const historicalGeoData =
      this.state.gaData.historical_geo_data &&
      this.state.gaData.historical_geo_data.rows
    const geoData = this.state.gaData.geo && this.state.gaData.geo.rows
    const leads = this.state.gaData.leads
    const monthlyLeads = this.state.gaData.leads_monthly
    const formatItem = item => {
      const path = item.replace(
        'https://s3.amazonaws.com/build-your-golf-cart/',
        ''
      )
      const categories = path.split('/')
      return categories
        .map(i => {
          const str = i.replace(/%20|_/g, ' ').replace('thumb.png', '')
          return str
            .toLowerCase()
            .split(' ')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1))
            .join(' ')
        })
        .join(' - ')
    }

    if (this.state.loading) {
      return (
        <div className="wrapper" style={wrapperStyle}>
          <LoadingSpinner />
        </div>
      )
    }

    if (this.state.error) {
      return (
        <div className="wrapper" style={wrapperStyle}>
          <p className="errorText">
            An error occurred, please try again later.
          </p>
        </div>
      )
    }
    return (
      <div>
        <h1>Stats</h1>
        <p>
          Top items selected within your cart builder. Check back frequently to
          see what your most popular items are!
        </p>
        <ReactToPrint
          trigger={() => <button href="#">Print Report</button>}
          content={() => this.componentRef}
          copyStyles
        />
        <div ref={el => (this.componentRef = el)}>
          <h3>Metrics</h3>
          <div style={{ display: 'inline-flex' }}>
            <div className="metrics-box">
              <p className="metrics-number">
                {allEvents && allEvents.toLocaleString('en')}
              </p>
              <p className="metrics-header">Clicks (All-Time)</p>
            </div>
            <div className="metrics-box">
              <p className="metrics-number">
                {monthlyEvents && monthlyEvents.toLocaleString('en')}
              </p>
              <p className="metrics-header">Clicks (30 Days)</p>
            </div>
            <div className="metrics-box">
              <p className="metrics-number">
                {allActiveUsers && allActiveUsers.toLocaleString('en')}
              </p>
              <p className="metrics-header">Users (All-Time)</p>
            </div>
            <div className="metrics-box">
              <p className="metrics-number">
                {monthlyActiveUsers && monthlyActiveUsers.toLocaleString('en')}
              </p>
              <p className="metrics-header">Users (30 Days)</p>
            </div>
          </div>
          <div>
            <h3>Leads</h3>
            <div style={{ display: 'inline-flex' }}>
              <div className="metrics-box">
                <p className="leads-number">{leads.count}</p>
                <p className="leads-header">Lead Count (All-Time)</p>
              </div>
              <div className="metrics-box">
                <p className="leads-number">{leads.total}</p>
                <p className="leads-header">Lead Amount (All-Time)</p>
              </div>
              <div className="metrics-box">
                <p className="leads-number">{monthlyLeads.count}</p>
                <p className="leads-header">Lead Count (30 days)</p>
              </div>
              <div className="metrics-box">
                <p className="leads-number">{monthlyLeads.total}</p>
                <p className="leads-header">Lead Amount (30 Days)</p>
              </div>
            </div>
          </div>
          <div
            className="charts"
            style={{ marginTop: 15, display: 'inline-flex' }}
          >
            <div>
              <h3>Pageviews</h3>
              <Chart chartData={this.state.gaData.chart.rows || []} />
            </div>
            <div>
              <h3>Users</h3>
              <Chart chartData={this.state.gaData.user_chart.rows || []} />
            </div>
          </div>
          <h3>Pageviews</h3>
          <div style={{ display: 'inline-flex' }}>
            <div className="metrics-box">
              <p className="metrics-number">
                {allTimePageViews && allTimePageViews.toLocaleString('en')}
              </p>
              <p className="metrics-header">All-Time</p>
            </div>
            <div className="metrics-box">
              <p className="metrics-number">
                {pageViews && pageViews.toLocaleString('en')}
              </p>
              <p className="metrics-header">Monthly (30 Days)</p>
            </div>
          </div>
          <h3>Top Clicks (All-Time)</h3>
          <div style={{ display: 'inline-flex' }}>
            {mostClickedEvents && mostClickedEvents.length > 0 ? (
              mostClickedEvents.map((e, index) => {
                return (
                  <div className="metrics-box" key={index}>
                    <p className="metrics-number">
                      {e[1] && e[1].toLocaleString('en')}
                    </p>
                    <p className="clicks-header">{formatItem(e[0])}</p>
                  </div>
                )
              })
            ) : (
              <p className="no-results">No Events Found</p>
            )}
          </div>
          <h3>Top Clicks (30 Days)</h3>
          <div style={{ display: 'inline-flex' }}>
            {mostClickedMonthlyEvents && mostClickedMonthlyEvents.length > 0 ? (
              mostClickedMonthlyEvents.map((e, index) => {
                return (
                  <div className="metrics-box" key={index}>
                    <p className="metrics-number">
                      {e[1] && e[1].toLocaleString('en')}
                    </p>
                    <p className="clicks-header">{formatItem(e[0])}</p>
                  </div>
                )
              })
            ) : (
              <p className="no-results">No Events Found</p>
            )}
          </div>
          <h3>User Locations (All-Time)</h3>
          <div style={{ display: 'inline-flex' }}>
            {historicalGeoData && historicalGeoData.length > 0 ? (
              historicalGeoData.map((e, index) => {
                return (
                  <div className="metrics-box" key={index}>
                    <p className="metrics-number">{e[2]}</p>
                    <p className="clicks-header">{`${e[0]}, ${e[1]}`}</p>
                  </div>
                )
              })
            ) : (
              <p className="no-results">No location data found.</p>
            )}
          </div>
          <h3>User Locations (30 Days)</h3>
          <div style={{ display: 'inline-flex' }}>
            {geoData && geoData.length > 0 ? (
              geoData.map((e, index) => {
                return (
                  <div className="metrics-box" key={index}>
                    <p className="metrics-number">{e[2]}</p>
                    <p className="clicks-header">{`${e[0]}, ${e[1]}`}</p>
                  </div>
                )
              })
            ) : (
              <p className="no-results">No location data found.</p>
            )}
          </div>
        </div>
      </div>
    )
  }
}
