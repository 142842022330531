import React, { Children } from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import Button from './Button'
import HList from './HList'

import { confirm } from '../utils'

import './Navigation.css'

const propTypes = {
  onSignOutClick: PropTypes.func.isRequired
}

export const Link = props => <NavLink activeClassName="active" {...props} />

const Navigation = props => (
  <nav className="Nav gutter">
    <div className="wrapper">
      <HList>
        <li>
          <Link exact to="/" style={{ display: 'flex', alignItems: 'center' }}>
            <span>Home</span>
            <img
              src="/images/logo.png"
              width={32}
              height={32}
              alt="BYGC Logo"
              style={{ marginLeft: 5 }}
            />
          </Link>
        </li>
        {Children.map(props.children, child => <li>{child}</li>)}
      </HList>

      <HList>
        {props.viewer && <li>{props.viewer.name}</li>}
        <li>
          <Button primary onClick={() => confirm(props.onSignOutClick)}>
            sign out
          </Button>
        </li>
      </HList>
    </div>
  </nav>
)

Navigation.propTypes = propTypes

export default Navigation
