import { combineReducers, createStore, applyMiddleware } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'

import reducers from '../reducers'

import { persist, retrieve } from './persist'

export default ({ apolloClient, persistor }) => {
  let middleware = [thunk.withExtraArgument({ apolloClient, persistor })]

  if (process.env.NODE_ENV !== 'production') {
    middleware.push(createLogger({ collapsed: true }))
  }

  const retrievedState = retrieve()

  const store = createStore(
    combineReducers({
      ...reducers
    }),
    retrievedState,
    applyMiddleware(...middleware)
  )

  store.subscribe(() => persist(store.getState()))

  return store
}
