import React from 'react'
import Switch from 'react-switch'
import styled from 'styled-components'

const SwitchContainer = styled.div`
  padding-top: 0.5rem;
`

class CustomSwitch extends React.Component {
  constructor(props) {
    super(props)
  }

  handleSwitch = checked => {
    this.setState({ isChecked: checked })
    this.props.switchChange(checked)
  }

  render() {
    const { checkValue } = this.props

    return (
      <SwitchContainer>
        <Switch
          width={70}
          height={22}
          checked={checkValue}
          onChange={() => this.handleSwitch(!checkValue)}
          offColor="#6c757d"
          onColor="#28a745"
          uncheckedIcon={
            <div
              style={{
                fontSize: '12.8px',
                color: 'white',
                paddingTop: '4px',
                marginLeft: '-14.5px'
              }}
            >
              Default
            </div>
          }
          checkedIcon={
            <div
              style={{
                fontSize: '12.8px',
                color: 'white',
                paddingTop: '4px',
                paddingLeft: '8px'
              }}
            >
              Preset
            </div>
          }
          className="react-switch"
        />
      </SwitchContainer>
    )
  }
}

export default CustomSwitch
