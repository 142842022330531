import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'

import DealerEditor from './DealerEditor'
import { WithLoadingSpinner } from '../../components/LoadingSpinner'

export default class EditDealerScreen extends React.Component {
  render() {
    console.log(this.props.match.params.id)
    return (
      <div
        style={{
          maxWidth: 600,
          margin: '0 auto'
        }}
      >
        <h1>Edit Dealer</h1>
        <DealerEditor id={this.props.match.params.id} />
      </div>
    )
  }
}
