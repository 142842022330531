import React, { Component } from 'react'
import { connect } from 'react-redux'
import * as R from 'ramda'

import './SignIn.css'

import { API_ROOT } from '../config'
import Button from '../components/Button'
import PasswordResetModal from '../components/PasswordResetModal'

import * as actions from '../actions'

class SignIn extends Component {
  constructor() {
    super()
    try {
      let { pathname, search } = window.location
      if (pathname === '/reset_password') {
        this.state.resetPassword = true
        this.state.resetPasswordToken = search.match(/^\?token=(.+)\&?/)[1]
      }
    } catch (e) {
      console.log(e)
    }
  }

  state = {
    isModalOpen: false,
    resetPassword: false,
    resetPasswordToken: null,
    resetPasswordError: null
  }

  onSubmit(e) {
    e.preventDefault()

    const email = R.trim(this.username.value)
    const password = R.trim(this.password.value)

    if (
      R.isNil(email) ||
      R.isEmpty(email) ||
      (R.isNil(password) || R.isEmpty(password))
    ) {
      return
    }

    this.props.authenticate(email, password)
  }

  loginErrorMessage = () =>
    this.props.loginError && (
      <div>
        <p>
          {this.props.loginError} - Please check your email/password and try
          again.
        </p>
      </div>
    )

  openModal() {
    this.setState({ isModalOpen: true })
  }

  closeModal = e => {
    if (e != null) {
      e.preventDefault()
    }
    this.setState({ isModalOpen: false })
  }

  handleSubmitReset(e) {
    e.preventDefault()
    let form = e.target
    let password = form.elements.password.value
    let passwordConfirm = form.elements.passwordConfirm.value
    let resetToken = this.state.resetPasswordToken
    fetch(API_ROOT + '/auth/password', {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        password,
        password_confirmation: passwordConfirm,
        reset_password_token: resetToken
      })
    })
      .then(res => res.json())
      .then(response => {
        if (response.status === 'ok') {
          window.alert(
            'Your password was reset. You may now log in with your new password.'
          )
        } else {
          window.alert(
            'There was an error resetting your password. Please try again.'
          )
        }
        window.location = '/'
      })
  }

  render() {
    if (this.state.resetPassword) {
      return (
        <div className="SignIn">
          <img
            src="/images/logo-full.png"
            alt="BYGC Logo"
            className="SignIn__logo"
            width={200}
            height={62}
          />
          <p> Reset Password </p>
          <form
            onSubmit={e => this.handleSubmitReset(e)}
            style={{ color: 'black' }}
          >
            <label htmlFor="password">
              <span>Password</span>
              <br />
              <br />
              <input
                id="password"
                type="password"
                placeholder="Password"
                autoFocus
              />
            </label>
            <label htmlFor="passwordConfirm">
              <span>Password Confirmation</span>
              <br />
              <br />
              <input
                id="passwordConfirm"
                type="password"
                placeholder="Confirm Password"
              />
            </label>
            <Button type="submit">Reset Password</Button>
            {this.state.resetPasswordError ? (
              <div>
                There was an error resetting your password. Please{' '}
                <a href="/">try again</a>.
              </div>
            ) : null}
          </form>
        </div>
      )
    }
    return (
      <div className="SignIn">
        <img
          src="/images/logo-full.png"
          alt="BYGC Logo"
          className="SignIn__logo"
          width={200}
          height={62}
        />
        <form onSubmit={e => this.onSubmit(e)}>
          <input
            type="text"
            placeholder="Email"
            autoFocus
            ref={i => {
              this.username = i
            }}
          />

          <input
            type="password"
            placeholder="Password"
            ref={i => {
              this.password = i
            }}
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'baseline'
            }}
          >
            <a onClick={() => this.openModal()} className="modal-link">
              Forgot your password?
            </a>
            <Button type="submit">Log In</Button>
          </div>
        </form>
        {this.loginErrorMessage()}
        <PasswordResetModal
          {...this.props}
          isOpen={this.state.isModalOpen}
          onClose={this.closeModal}
        />
      </div>
    )
  }
}

SignIn.defaultProps = { loginError: null }

const mapStateToProps = state => ({ loginError: state.auth.loginError })

export default connect(mapStateToProps, dispatch => {
  return {
    authenticate: (email, password) => {
      dispatch(actions.authenticate(email, password))
    }
  }
})(SignIn)
