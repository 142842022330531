import React from 'react'
import ReactDOM from 'react-dom'
import * as R from 'ramda'
import { Provider as ReduxProvider } from 'react-redux'
import { ApolloProvider } from 'react-apollo'
import ApolloClient from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { createHttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { CachePersistor } from 'apollo-cache-persist'
import gql from 'graphql-tag'
import localForage from 'localforage'

import * as actions from './actions'
import { API_ROOT } from './config'

import configureStore from './store'

import Routes from './routes'

import './index.css'

const getToken = state =>
  `Bearer ${R.path(['auth', 'token', 'access_token'], state)}`

class StateProvider extends React.Component {
  constructor(props) {
    super(props)

    const cache = new InMemoryCache()

    const persistor = new CachePersistor({
      cache,
      storage: localForage
    })

    const httpLink = createHttpLink({
      uri: `${API_ROOT}/graphql`
    })

    const middlewareLink = new ApolloLink((operation, forward) => {
      const token = getToken(store.getState())
      if (token) {
        operation.setContext({
          headers: {
            authorization: token
          }
        })
      }

      return forward(operation)
    })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors != null) {
        for (let err of graphQLErrors) {
          console.error(err.message)
        }
      }
      if (networkError != null && networkError.statusCode === 401) {
        store.dispatch(actions.deauthenticate())
      }
    })

    // use with apollo-client
    const link = middlewareLink.concat(errorLink.concat(httpLink))
    const apolloClient = new ApolloClient({ link, cache })

    const store = configureStore({ apolloClient, persistor })

    this.apolloClient = apolloClient
    this.store = store
  }

  render() {
    return (
      <ApolloProvider client={this.apolloClient}>
        <ReduxProvider store={this.store}>
          <Routes />
        </ReduxProvider>
      </ApolloProvider>
    )
  }
}

ReactDOM.render(
  <StateProvider>
    <Routes />
  </StateProvider>,
  document.getElementById('root')
)
