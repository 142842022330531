import React from 'react'

const NotFound = props => (
  <div>
    <h1>404</h1>
    <h2>Couldn't find {props.location.pathname}</h2>
  </div>
)

export default NotFound
