import React from 'react'

export const getEmbedURL = guid => {
  if (process.env.NODE_ENV !== 'production') {
    return `http://localhost:3002/${guid}`
  }
  return `https://app.buildyourgolfcart.com/${guid}`
}

export default props => (
  <div className="pre">
    {`<iframe src="${getEmbedURL(
      props.guid
    )}" width="100%" height="800"></iframe>`}
  </div>
)
