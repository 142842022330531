import React from 'react'
import { Route } from 'react-router-dom'

import Navigation, { Link } from '../components/Navigation'
import { With404 } from './'

import BuilderPreview from '../screens/BuilderPreview'
import OptionSelectionView from '../screens/OptionSelectionView'
import UserHome from '../screens/UserHome'
import UserSavesMain from '../screens/UserSaves/Main'
import UserSavesDetail from '../screens/UserSaves/Detail'
import StatsMain from '../screens/Stats/Main'
import Coupons from '../screens/Coupons/Main'

export default props => (
  <div className="wrapper" key="not-admin">
    <Navigation viewer={props.data.viewer} onSignOutClick={props.signOut}>
      <Link to="/leads">Leads</Link>
      <Link to="/options">Options</Link>
      <Link to="/preview">Preview + Embed</Link>
      <Link to="/stats">Stats</Link>
      <Link to="/coupons">Coupons</Link>
    </Navigation>
    <With404>
      <Route
        exact
        path="/"
        component={() => <UserHome userId={props.data.viewer.id} />}
      />

      <Route exact path="/leads" component={UserSavesMain} />
      <Route exact path="/leads/:id" component={UserSavesDetail} />

      <Route
        exact
        path="/preview"
        component={() => (
          <BuilderPreview
            guid={props.data.viewer.builder.guid}
            user={props.data.viewer}
          />
        )}
      />

      <Route
        exact
        path="/options"
        component={() => <OptionSelectionView userId={props.data.viewer.id} />}
      />

      <Route
        exact
        path="/stats"
        component={() => <StatsMain userId={props.data.viewer.id} />}
      />

      <Route
        exact
        path="/coupons"
        component={() => <Coupons guid={props.data.viewer.builder.guid} />}
      />
    </With404>
  </div>
)
