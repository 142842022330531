import React from 'react'
import { withRouter } from 'react-router-dom'
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import styled from 'styled-components'

import BaseModelEditor from '../components/BaseModelEditor'
import Button from '../components/Button'
import UserSaveTable from '../components/UserSaveTable'
import LoadingSpinner from '../components/LoadingSpinner'
import PriceDisplaySelector from './UserHomePriceDisplaySelector'

export const HeadingWrapper = styled.div`
  align-items: baseline;
  display: flex;
`

const query = gql`
  query Home($user_id: ID!) {
    cart_models {
      id
      brand
      model
      available_options {
        id
      }
    }
    user(id: $user_id) {
      id
      name
      location
      business_name
      admin
      active

      builders {
        active
        guid
        cart_model {
          id
        }
        base_models {
          price
          description
        }
        options {
          edges {
            price
            node {
              id
              label
              parent {
                id
                label
              }
            }
          }
        }
        user_saves(first: 5) {
          edges {
            node {
              ...userSaveInfo
            }
          }
        }
      }
    }
  }
  ${UserSaveTable.fragment}
`

const CartModelsWrapper = styled.div`
  padding: 0;
  color: #111;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const CartModel = styled.div`
  background: #fffefd;
  width: 100%;
  max-width: calc(100%/3 - 10px);
  padding: 1rem;
  opacity: ${props => (props.active ? 1 : 0.6)};
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
`

const Toggle = ({ checked, label, onChange }) => (
  <div>
    <label
      style={{
        cursor: 'pointer',
        display: 'grid',
        gridTemplateColumns: '1.75rem min-content',
        alignItems: 'center',
        width: 'fit-content'
      }}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange || (() => {})}
      />
      {label}
    </label>
  </div>
)

const SectionHeader = styled.h4`
  font-size: 1.6rem;
  margin: 2rem 0 1rem;
  border-bottom: 1px solid #a2a2a2;
  width: 100%;
  line-height: 1.25;
`

class Home extends React.Component {
  state = {
    busy: false
  }

  render() {
    const { loading, error, cart_models, user } = this.props.data
    if (loading) return <LoadingSpinner />
    if (error != null) {
      console.error(error)
      return <div>error</div>
    }

    return (
      <div>
        <HeadingWrapper>
          <h1>Cart Models</h1>
        </HeadingWrapper>
        <p>
          <strong>Home (Cart Models)</strong>
          <ul>
            <li>
              Select the 'active' checkbox for the cart models you'd like to
              show.
            </li>
            <li>
              Make sure to click 'edit' under Base Models and add a name and
              price and then click save. You need at least one name and price
              active under each cart model base models for your cart builder to
              be enabled.
            </li>
            <li>
              Configure button will take you to the options tab where you can
              select all your items.
            </li>
          </ul>
        </p>
        <CartModelsWrapper>
          {cart_models.map(({ id, available_options, brand, model }) => {
            const user_builder = user.builders.find(b => {
              if (b.cart_model) {
                if (b.cart_model.id === id) {
                  return b
                }
              }
            })
            const active = user_builder != null && user_builder.active
            return (
              <CartModel key={id} active={active}>
                <h3
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 0,
                    marginBottom: '0.75rem',
                    fontSize: '2.2rem'
                  }}
                >
                  <span
                    style={{
                      fontSize: '1.1rem',
                      textTransform: 'uppercase',
                      letterSpacing: '-0.05rem',
                      color: 'rgba(0, 0, 0, 0.8)',
                      marginBottom: '-0.4rem'
                    }}
                  >
                    {brand}
                  </span>
                  {model}
                </h3>
                <Toggle
                  checked={active}
                  label={active ? 'Active' : 'Inactive'}
                  value={id}
                  onChange={e => this.handleActiveChange(e.target.checked, id)}
                />
                {active ? (
                  <PriceDisplaySelector builderId={user_builder.guid} />
                ) : null}
                <div style={{ pointerEvents: active ? 'auto' : 'none' }}>
                  <SectionHeader>Options</SectionHeader>
                  {user_builder != null && (
                    <div
                      style={{
                        marginBottom: '1rem',
                        fontSize: '1.4rem',
                        textAlign: 'center'
                      }}
                    >
                      <span
                        style={{
                          fontWeight: 'bold',
                          fontSize: '2rem',
                          padding: '0 0.4rem'
                        }}
                      >
                        {user_builder.options.edges.length}
                      </span>
                      of
                      <span
                        style={{
                          fontSize: '2rem',
                          padding: '0 0.4rem'
                        }}
                      >
                        {available_options.length}
                      </span>
                      enabled
                    </div>
                  )}
                  <Button
                    wide
                    onClick={() =>
                      this.props.history.push(
                        this.props.optionsURL || '/options'
                      )
                    }
                  >
                    Configure
                  </Button>
                  <SectionHeader>Base Models</SectionHeader>
                  {user_builder != null && (
                    <div style={{ marginBottom: '1rem' }}>
                      <BaseModelEditor builderId={user_builder.guid} />
                    </div>
                  )}
                </div>
              </CartModel>
            )
          })}
        </CartModelsWrapper>
      </div>
    )
  }

  handleActiveChange = (checked, cart_model_id) => {
    if (this.state.busy) return
    const builder = this.props.data.user.builders.find(b => {
      if (b.cart_model) {
        if (b.cart_model.id === cart_model_id) {
          return b
        }
      }
    })
    if (checked) {
      // We're activating a builder.
      if (builder != null) {
        //   If we have a builder already, activate it.
        this.isBusyWhile(
          this.props.setBuilderActiveStatus({
            builder_id: builder.guid,
            active: true
          })
        ).then(this.props.data.refetch)
      } else {
        const user_id = this.props.data.user.id
        //   Else, create one.
        this.isBusyWhile(
          this.props.createBuilder({
            user_id,
            cart_model_id
          })
        )
          .then(this.props.data.refetch)
          .catch(console.error)
      }
    } else {
      // We're deactivating a builder.
      //   If there is a builder, deactivate it
      if (builder != null) {
        this.isBusyWhile(
          this.props.setBuilderActiveStatus({
            builder_id: builder.guid,
            active: false
          })
        ).then(this.props.data.refetch)
      }
      //   Else, do nothing
    }
  }

  // I don't know how much I like this but it's maybe okay?
  isBusyWhile = thisHappens => {
    this.setState({ busy: true })
    return thisHappens
      .then(result => {
        this.setState({ busy: false })
        return result
      })
      .catch(error => {
        this.setState({ busy: false })
        throw error
      })
  }
}

export default compose(
  graphql(query, {
    options: props => ({
      variables: { user_id: props.userId }
    })
  }),
  graphql(
    gql`
      mutation SetBuilderActiveStatus($fields: SetBuilderActiveStatusFields!) {
        setBuilderActiveStatus(fields: $fields) {
          active
        }
      }
    `,
    {
      props: ({ mutate }) => {
        return {
          setBuilderActiveStatus: ({ builder_id, active }) =>
            mutate({ variables: { fields: { builder_id, active } } })
        }
      }
    }
  ),
  graphql(
    gql`
      mutation CreateBuilder($fields: CreateBuilderFields!) {
        createBuilder(fields: $fields) {
          guid
          cart_model {
            id
          }
        }
      }
    `,
    {
      props: ({ mutate }) => {
        return {
          createBuilder: ({ user_id, cart_model_id }) =>
            mutate({ variables: { fields: { user_id, cart_model_id } } })
        }
      }
    }
  )
)(withRouter(Home))
