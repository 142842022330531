import React, { Component } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Button from './Button'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  parentId: PropTypes.string,
  initialValues: PropTypes.object
}

const initialState = {
  name: '',
  label: '',
  editing: false
}

const Form = styled.form`
  background: white;
  color: black;
  border: 1px solid #aaa;
  border-radius: 3px;
  width: 100%;
  padding: 5px;
`

const Input = styled.input`
  border: 1px solid grey;
  border-radius: 3px;
  padding: 5px;
  width: 100%;
`

const Row = styled.div`
  margin: -0.5rem 0 0.5rem -0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  &:last-child {
    margin-bottom: 0;
  }
`

const Cell = styled.div`
  padding: 0.5rem 0 0 0.5rem;

  label {
    font-size: 0.9rem;
    font-family: monospace;
    line-height: 1.1;
    display: inline-block;
    margin-bottom: 6px;
  }
`

class AddOptionForm extends Component {
  static propTypes = propTypes

  constructor(props) {
    super(props)

    this.state = {
      ...initialState,
      ...props.initialValues,
      editing: props.initialValues != null
    }
  }

  onSubmit(e) {
    e.preventDefault()
    const { name, label } = this.state
    // TODO: levitate levitate levitate validate
    this.props.onSubmit(name, label, this.props.parentId)
    if (!this.state.editing) {
      this.setState({ ...initialState })
    }
  }

  field = (name, placeholder) => {
    return (
      <Input
        type="text"
        placeholder={placeholder || name}
        value={this.state[name]}
        onChange={e => this.setState({ [name]: e.target.value })}
      />
    )
  }

  render() {
    const { field } = this
    return (
      <Form onSubmit={e => this.onSubmit(e)}>
        <Row>
          <Cell>
            <label>label (e.g.&nbsp;"My&nbsp;Option")</label>
            {field('label', 'label')}
          </Cell>
          <Cell>
            <label>name (e.g.&nbsp;"my_option")</label>
            {field('name', 'name')}
          </Cell>
          <Cell style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button primary type="submit">
              {this.state.editing ? 'Save' : 'Add'}
            </Button>
          </Cell>
        </Row>
      </Form>
    )
  }
}

export default AddOptionForm
