import React from 'react'
import { API_ROOT } from '../../config'
import LoadingSpinner from '../../components/LoadingSpinner'
import CouponTable from './Table'
import EditCoupon from './Edit'
import FormErrors from './FormErrors'

export default class Coupons extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      coupons: [],
      loading: false,
      error: false,
      formData: {
        expiration: '',
        code: '',
        limit: '',
        percentage: ''
      },
      selectedCoupon: {},
      editing: false
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.openEditForm = this.openEditForm.bind(this)
    this.closeEditForm = this.closeEditForm.bind(this)
    this.fetchCoupons = this.fetchCoupons.bind(this)
  }

  componentDidMount() {
    this.setState({ loading: true })
    this.fetchCoupons()
  }

  fetchCoupons() {
    return window
      .fetch(API_ROOT + `/coupons/${this.props.guid}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then(r => r.json())
      .then(json => {
        this.setState({ coupons: json.coupons, loading: false })
      })
      .catch(_ => this.setState({ error: true, loading: false }))
  }

  updateField(ev) {
    const formData = {
      ...this.state.formData,
      [ev.target.name]: ev.target.value
    }
    this.setState({ ...this.state, formData: formData })
  }

  handleSubmit() {
    this.setState({ loading: true })
    return window
      .fetch(API_ROOT + '/coupons', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          coupon: this.state.formData,
          guid: this.props.guid
        })
      })
      .then(res => {
        if (res.status === 422) {
          res
            .json()
            .then(r => this.setState({ formErrors: true, errors: r.errors }))
        }
        this.fetchCoupons()
      })
      .catch(_ => {
        this.setState({ error: true, loading: false })
      })
  }

  openEditForm({ id }) {
    const coupon =
      this.state.coupons && this.state.coupons.filter(c => c.id === id).pop()
    this.setState({ editing: true, selectedCoupon: coupon })
  }

  closeEditForm() {
    this.setState({ editing: false, selectedCoupon: {} })
  }

  render() {
    const wrapperStyle = {
      display: 'flex',
      justifyContent: 'center',
      paddingTop: 20,
      paddingBottom: 20
    }

    const minDate = new Date(Date.now()).toISOString().split('T')[0]

    if (this.state.error) {
      return (
        <div className="wrapper" style={wrapperStyle}>
          <p className="errorText">
            An error occurred, please try again later.
          </p>
        </div>
      )
    }

    return (
      <div>
        {this.state.editing ? (
          <EditCoupon
            refresh={this.fetchCoupons}
            closeForm={this.closeEditForm}
            guid={this.props.guid}
            coupon={this.state.selectedCoupon}
          />
        ) : (
          <div>
            <h3>Create A Coupon</h3>
            <FormErrors errors={this.state.errors} />
            <div className="app-form">
              <form
                onSubmit={e => {
                  e.preventDefault()
                  this.handleSubmit()
                }}
              >
                <p className="form-label">Expiration</p>
                <input
                  type="date"
                  name="expiration"
                  min={minDate}
                  onChange={e => this.updateField(e)}
                />
                <p className="form-label">Code</p>
                <input
                  type="text"
                  name="code"
                  placeholder="Ex. SAVE20"
                  onChange={e => this.updateField(e)}
                />
                <p className="form-label">Percentage</p>
                <input
                  type="number"
                  min={0}
                  max={99}
                  name="percentage"
                  onChange={e => this.updateField(e)}
                />
                <p className="form-label">Amount</p>
                <input
                  type="number"
                  name="limit"
                  onChange={e => this.updateField(e)}
                />
                <input
                  type="submit"
                  value={this.state.loading ? 'Submitting...' : 'Create'}
                />
              </form>
            </div>
            <CouponTable {...{ openForm: this.openEditForm, ...this.state }} />
          </div>
        )}
      </div>
    )
  }
}
