import React from 'react'
import { CartesianGrid, Line, XAxis, YAxis, LineChart } from 'recharts'

export default class Chart extends React.Component {
  render() {
    const chartData = this.props.chartData.map((a, i) => {
      const year = a[0] && a[0].substring(2, 4)
      const mm = a[0] && a[0].substring(4, 6)
      return { name: `${mm}/${year}`, x: i + 1, y: parseInt(a[1]) }
    })
    return (
      <LineChart width={800} height={400} data={chartData}>
        <XAxis dataKey="name" stroke="white" />
        <YAxis stroke="white" />
        <CartesianGrid strokeDasharray="5 5" />
        <Line type="monotone" dataKey="y" stroke="white" />
      </LineChart>
    )
  }
}
