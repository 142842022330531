import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { connect } from 'react-redux'

import * as actions from '../actions'
import './PasswordResetModal.css'

import Button from './Button'

class Modal extends React.Component {
  state = {
    email: '',
    resetEmailSent: false
  }

  handleSubmit = event => {
    event.preventDefault()
    const email = this.state.email.trim()
    if (email === '') {
      return
    }

    this.props.sendResetEmail(email)

    this.setState({ resetEmailSent: true })
  }

  render() {
    const { onClose, isOpen } = this.props
    if (!isOpen) return null
    return (
      <div className="SignIn__modal_backdrop">
        <div className="SignIn__modal">
          {this.state.resetEmailSent ? (
            <div>
              <p className="modal_text">
                An email has been sent to {this.state.email} with instructions
                for resetting your password.
              </p>
              <div>
                <Button onClick={onClose}>Okay</Button>
              </div>
            </div>
          ) : (
            <form onSubmit={this.handleSubmit}>
              <p className="modal_text">
                Enter your email and we will send you instructions on how to
                reset your password!
              </p>
              <input
                type="text"
                placeholder="Email"
                autoFocus
                onChange={e => {
                  this.setState({ email: e.target.value })
                }}
                value={this.state.email}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'baseline'
                }}
              >
                <Button onClick={onClose} type="button">
                  Cancel
                </Button>
                <Button primary type="submit">
                  Submit
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    )
  }
}

// const sendPasswordResetMutation = gql`
// mutation SendPasswordReset($email: String!) {
//   sendPasswordResetEmail (email: $email) {
//     id
//     email
//   }
// }
// `;

// export default graphql(sendPasswordResetMutation, {
//   props: ({ mutate }) => ({
//     sendResetEmail: email => mutate({ variables: { email } })
//   })
// })(Modal);

export default connect(null, dispatch => {
  return {
    sendResetEmail: email => {
      dispatch(actions.resetPassword(email))
    }
  }
})(Modal)
