import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import { WithLoadingSpinner } from '../../components/LoadingSpinner'

const query = gql`
  query DealersPage {
    users {
      id
      business_name
      name
      email
    }
  }
`

const Dealers = props => (
  <WithLoadingSpinner {...props}>
    {({ data }) => (
      <div>
        <h1>Dealers</h1>
        <Link className="btn" to="/dealers/new">
          Add New
        </Link>
        {_.orderBy(data.users, ['business_name'], ['asc']).map(user => (
          <div style={{ display: 'flex' }} key={user.email}>
            <Link
              className="anchor-plain"
              to={`/dealers/${user.id}`}
              style={{
                display: 'inline-flex',
                flex: 1,
                flexDirection: 'column'
              }}
            >
              <div className="Card islet gutter">
                <div>
                  <div>
                    <h2 className="h2">{user.business_name}</h2>
                  </div>
                  <div>{user.name}</div>
                  <div>
                    <i>{user.email}</i>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    )}
  </WithLoadingSpinner>
)

const WithData = graphql(query)

export default WithData(Dealers)
