import React from 'react'
import { Route } from 'react-router-dom'

import Navigation, { Link } from '../components/Navigation'
import { With404 } from './'

// import BuilderDetail from '../screens/Builders/Detail'
import BuilderPreview from '../screens/BuilderPreview'
import BuildersMain from '../screens/Builders/Main'
import DealerDetail from '../screens/Dealers/Detail'
import DealerNew from '../screens/Dealers/New'
import DealerEdit from '../screens/Dealers/Edit'
import DealersMain from '../screens/Dealers/Main'
import OptionsAdmin from '../screens/OptionsAdmin'
import UserSavesDetail from '../screens/UserSaves/Detail'
import UserSavesMain from '../screens/UserSaves/Main'
import OptionSelectionView from '../screens/OptionSelectionView'
import StatsMain from '../screens/Stats/Main'

export default props => (
  <div className="wrapper" key="admin">
    <Navigation viewer={props.data.viewer} onSignOutClick={props.signOut}>
      <Link to="/options">Options</Link>
      <Link to="/leads">Leads</Link>
    </Navigation>
    <With404>
      <Route exact path="/" component={DealersMain} />

      <Route
        exact
        path="/preview/:guid"
        component={props => <BuilderPreview guid={props.match.params.guid} />}
      />

      <Route exact path="/dealers" component={DealersMain} />
      <Route path="/dealers/new" component={DealerNew} />
      <Route path="/dealers/edit/:id" component={DealerEdit} />
      <Route
        exact
        path="/dealers/:id/options"
        component={props => (
          <OptionSelectionView userId={props.match.params.id} />
        )}
      />
      <Route
        exact
        path="/dealers/:id/stats"
        component={props => <StatsMain userId={props.match.params.id} />}
      />
      <Route path="/dealers/:id" component={DealerDetail} />
      <Route exact path="/leads/:id" component={UserSavesDetail} />
      <Route
        path="/leads"
        component={() => <UserSavesMain admin={props.data.viewer.admin} />}
      />

      <Route exact path="/options" component={OptionsAdmin} />
    </With404>
  </div>
)
