import React from 'react'
import gql from 'graphql-tag'
import { compose, graphql } from 'react-apollo'

class OptionOrderForm extends React.Component {
  handleSubmit = e => {
    e.preventDefault()
    const value = parseInt(this.input.value, 10)
    this.props.setOrder(value)
  }

  render() {
    if (this.props.data.loading) return null

    return (
      <form>
        <label>
          order:{' '}
          <input
            type="number"
            ref={i => {
              this.input = i
            }}
            defaultValue={this.props.data.option.order}
          />
        </label>{' '}
        <button onClick={this.handleSubmit}>save</button>
      </form>
    )
  }
}

const WithOptionOrder = graphql(
  gql`
    query OptionOrderForm($id: ID!) {
      option(id: $id) {
        order
      }
    }
  `,
  {
    options: props => ({ variables: { id: props.optionId } })
  }
)

const WithUpdateOrder = graphql(
  gql`
    mutation SetOptionOrder($option_id: ID!, $order: Int!) {
      setOrder(option_id: $option_id, order: $order) {
        id
        order
      }
    }
  `,
  {
    props: ({ mutate, ownProps: props }) => ({
      setOrder: order =>
        mutate({
          variables: {
            option_id: props.optionId,
            order
          }
        })
    })
  }
)

export default compose(WithOptionOrder, WithUpdateOrder)(OptionOrderForm)
