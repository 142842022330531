import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { connect } from 'react-redux'
import gql from 'graphql-tag'
import { graphql } from 'react-apollo'

import * as actions from '../actions'
import { getAccessToken } from '../reducers'
import { WithLoadingSpinner } from '../components/LoadingSpinner'
import NotFound from '../screens/NotFound'
import SignIn from '../screens/SignIn'

import AdminRoutes from './admins'
import UserRoutes from './users'

export const With404 = props => (
  <Switch>
    {props.children}
    <Route component={NotFound} />
  </Switch>
)

const WithViewer = graphql(
  gql`
    {
      viewer {
        id
        admin
        name
        active
        builder {
          guid
        }
      }
    }
  `
)

const Routes = WithViewer(props => (
  <Router>
    <WithLoadingSpinner {...props}>
      {props =>
        props.data.viewer.admin ? AdminRoutes(props) : UserRoutes(props)
      }
    </WithLoadingSpinner>
  </Router>
))

export default connect(
  state => ({
    signedIn: getAccessToken(state) != null
  }),
  dispatch => ({
    signOut: () => dispatch(actions.deauthenticate())
  })
)(props => (props.signedIn ? <Routes {...props} /> : <SignIn />))
