import React, { Component } from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import * as R from 'ramda'
import styled from 'styled-components'

import AddOptionForm from '../components/AddOptionForm'
import Button from '../components/Button'
import { confirm, getURLS, imageBase } from '../utils'
import OptionAvailabilityToggler from '../components/OptionAvailabilityToggler'
import OptionOrderForm from '../components/OptionOrderForm'
import OptionZindexForm from '../components/OptionZindexForm'
import { WithLoadingSpinner } from '../components/LoadingSpinner'

const query = gql`
  query OptionsPage {
    cart_models {
      id
      brand
      model
      available_options {
        id
      }
    }

    options(root: true) {
      id
      name
      label
      children {
        id
        name
        label
      }
    }
  }
`

const Row = styled.div`
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`

const OptionName = styled.span`
  font-family: monospace;
  font-size: 1.1rem;
  &:after {
    content: '>';
  }
  &:before {
    content: '<';
  }
`

const Selecto = styled.select`
  -webkit-appearance: none;
  border: none;
  background: none;
  color: white;
  font-size: 2.5rem;
  font-weight: bold;
  border: 1px solid white;
  padding: 0.5rem 1rem;
  width: calc(50% - 5px);
  position: relative;
  width: 100%;
  margin-bottom: 20px;
`

class OptionsAdminView extends Component {
  state = {
    selectedModel: this.props.cartModels[0].id,
    selectedOption: this.props.options[0].children[0].id
  }

  availableOptionsByCartModel = this.props.cartModels.reduce((acc, model) => {
    acc[model.id] = model.available_options.map(option => option.id)
    return acc
  }, {})

  handleCartModelChange(id) {
    this.setState({ selectedModel: id })
  }

  handleViewOption(id) {
    this.setState({ selectedOption: id })
  }

  renderOptionsList() {
    const availableOptions = this.availableOptionsByCartModel[
      this.state.selectedModel
    ]
    return (
      <div>
        {this.props.options.map(option => (
          <div
            key={option.id}
            style={{
              padding: 10,
              marginBottom: 10,
              background: 'white',
              color: 'black'
            }}
          >
            <Row>
              <div>
                <b className="h3">{option.label}</b>{' '}
                <OptionName>{option.name}</OptionName>
              </div>
              <div>
                <Button
                  danger
                  onClick={() => confirm(this.props.removeOption, option.id)}
                >
                  Remove
                </Button>
              </div>
            </Row>

            <div className="landmarklet">
              {option.children.length === 0 && (
                <div>
                  <i>No child options.</i>
                </div>
              )}
              {option.children.map(child => (
                <div
                  key={child.id}
                  className={`Card gutter ${
                    availableOptions.includes(child.id) ? '' : 'disabled'
                  }`}
                >
                  <div style={{ width: '100%' }}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                      }}
                    >
                      <b className="h4">{child.label}</b>
                      <Button onClick={() => this.handleViewOption(child.id)}>
                        View
                      </Button>
                    </div>

                    <div style={{ display: 'none' }}>
                      <div style={{ textAlign: 'right', flex: 1 }}>
                        <Button
                          danger
                          onClick={() =>
                            confirm(this.props.removeOption, child.id)
                          }
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <AddOptionForm
              onSubmit={this.props.addChildOption}
              parentId={option.id}
            />
          </div>
        ))}

        <h3>Add a Root Option</h3>
        <AddOptionForm
          onSubmit={(name, label) => {
            this.props.addRootOption(name, label)
          }}
        />
      </div>
    )
  }

  renderOptionView() {
    const selectedModel = this.props.cartModels.find(
      m => m.id === this.state.selectedModel
    )
    const selectedOption = this.props.options
      .map(g => ({
        parent: g,
        option: g.children.find(o => o.id === this.state.selectedOption)
      }))
      .filter(x => x.option != null)[0]

    const { base: baseURL, mod: modURL, thumb: thumbURL } = getURLS(
      selectedModel.brand,
      selectedModel.model,
      selectedOption.parent.name,
      selectedOption.option.name
    )

    return (
      <div>
        <div
          style={{
            position: 'relative',
            height: 0,
            paddingBottom: '66.66%',
            border: '1px dashed white'
          }}
        >
          <img
            src={baseURL}
            alt=""
            key={selectedModel.id + 'base'}
            style={{ position: 'absolute', width: '100%' }}
          />
          <img
            src={modURL}
            key={selectedModel.id + 'mod'}
            alt=""
            style={{
              position: 'absolute',
              width: '100%'
            }}
          />
          <img
            src={thumbURL}
            alt=""
            key={selectedModel.id + 'thumb'}
            style={{
              position: 'absolute',
              bottom: 5,
              right: 5,
              border: '1px dashed white'
            }}
          />
        </div>
        <div className="islet">
          <div className="h2">{selectedOption.option.label}</div>
          <OptionAvailabilityToggler
            key={selectedOption.option.id}
            cartModelId={this.state.selectedModel}
            optionId={this.state.selectedOption}
          />
        </div>
        <div className="landmarklet">
          <AddOptionForm
            key={selectedOption.option.id}
            onSubmit={(...values) => {
              this.props.updateChildOption(selectedOption.option.id, ...values)
            }}
            parentId={selectedOption.parent.id}
            initialValues={selectedOption.option}
          />
        </div>
        <div className="landmarklet">
          <OptionOrderForm
            key={selectedOption.option.id}
            optionId={this.state.selectedOption}
          />
          <br />
          <OptionZindexForm
            key={selectedOption.option.id + 1}
            optionId={this.state.selectedOption}
          />
        </div>
        <div style={{ fontSize: '.8rem', whiteSpace: 'nowrap' }}>
          {modURL.replace(imageBase, '')}
        </div>
        <div style={{ fontSize: '.8rem', whiteSpace: 'nowrap' }}>
          {thumbURL.replace(imageBase, '')}
        </div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <div style={{ display: 'flex' }}>
          <div style={{ width: '50%', marginLeft: '50%', marginTop: '60px' }}>
            {this.renderOptionsList()}
          </div>
          <div
            style={{
              maxWidth: '33rem',
              padding: '0px 0px 10px 10px',
              position: 'fixed',
              top: '140px',
              right: 'calc(50% + 10px)',
              width: 'calc(50% - 10px)'
            }}
          >
            <Selecto
              onChange={e => {
                this.handleCartModelChange(e.target.value)
              }}
            >
              {this.props.cartModels.map(({ id, brand, model }) => (
                <option key={id} value={id}>
                  {brand} {model}
                </option>
              ))}
            </Selecto>
            {this.renderOptionView()}
          </div>
        </div>
      </div>
    )
  }
}

const Options = props => (
  <WithLoadingSpinner {...props}>
    {({ data }) => (
      <OptionsAdminView
        cartModels={data.cart_models}
        options={data.options}
        addRootOption={props.addRootOption}
        addChildOption={props.addChildOption}
        updateChildOption={props.updateChildOption}
        removeOption={props.removeOption}
      />
    )}
  </WithLoadingSpinner>
)

const WithData = graphql(query)

const WithAddRootOption = graphql(
  gql`
    mutation AddRootOption($name: String!, $label: String!) {
      addOption(name: $name, label: $label) {
        id
        name
        label
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      addRootOption: (name, label) =>
        mutate({
          variables: { name, label },
          optimisticResponse: {
            __typename: 'Mutation',
            addOption: {
              __typename: 'Option',
              id: '',
              name,
              label,
              children: []
            }
          },
          updateQueries: {
            OptionsPage: (previousQueryResult, { mutationResult }) =>
              R.objOf(
                'options',
                R.append(
                  R.assoc('children', [], mutationResult.data.addOption),
                  previousQueryResult.options
                )
              )
          }
        })
    })
  }
)

const WithAddChildOption = graphql(
  gql`
    mutation AddChildOption($name: String!, $label: String!, $parent: ID) {
      addOption(name: $name, label: $label, parent: $parent) {
        id
        name
        label
        parent {
          id
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      addChildOption: (name, label, parent) =>
        mutate({
          variables: { name, label, parent },
          optimisticResponse: {
            __typename: 'Mutation',
            addOption: {
              __typename: 'Option',
              id: '',
              name,
              label,
              parent: { id: parent }
            }
          },
          updateQueries: {
            OptionsPage: (previousQueryResult, { mutationResult }) => {
              const option = mutationResult.data.addOption
              const parentIndex = R.findIndex(R.propEq('id', option.parent.id))(
                previousQueryResult.options
              )
              return R.objOf(
                'options',
                R.adjust(
                  R.evolve({ children: R.append(option) }),
                  parentIndex,
                  previousQueryResult.options
                )
              )
            }
          }
        })
    })
  }
)

const WithRemoveOption = graphql(
  gql`
    mutation RemoveOption($id: ID!) {
      removeOption(id: $id) {
        id
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      removeOption: id =>
        mutate({
          variables: { id },
          optimisticResponse: {
            __typename: 'Mutation',
            removeOption: {
              __typename: 'Option',
              id
            }
          },
          updateQueries: {
            OptionsPage: (previousQueryResult, { mutationResult }) => {
              // TODO clean this up
              const removedId = mutationResult.data.removeOption.id
              return {
                options: R.reduce(
                  (acc, option) => {
                    if (option.id === removedId) return acc
                    return R.append(
                      R.evolve(
                        {
                          children: R.reject(R.propEq('id', removedId))
                        },
                        option
                      ),
                      acc
                    )
                  },
                  [],
                  previousQueryResult.options
                )
              }
            }
          }
        })
    })
  }
)

const WithUpdateOption = graphql(
  gql`
    mutation UpdateChildOption($id: ID!, $name: String, $label: String) {
      updateOption(id: $id, name: $name, label: $label) {
        id
        name
        label
        parent {
          id
        }
      }
    }
  `,
  {
    props: ({ mutate }) => ({
      updateChildOption: (id, name, label, parentId) =>
        mutate({
          variables: { id, name, label },
          optimisticResponse: {
            __typename: 'Mutation',
            updateOption: {
              __typename: 'Option',
              id,
              name,
              label,
              parent: { id: parentId }
            }
          },
          updateQueries: {
            OptionsPage: (previousQueryResult, { mutationResult }) => {
              return R.objOf(
                'options',
                R.map(option => {
                  return R.assoc(
                    'children',
                    R.map(child => {
                      if (child.id === id) {
                        return mutationResult.data.updateOption
                      } else {
                        return child
                      }
                    }, option.children),
                    option
                  )
                }, previousQueryResult.options)
              )
            }
          }
        })
    })
  }
)

export default R.compose(
  WithData,
  WithAddRootOption,
  WithAddChildOption,
  WithRemoveOption,
  WithUpdateOption
)(Options)
