import React, { Component } from 'react'
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'
import Button from './Button'

import { WithLoadingSpinner } from './LoadingSpinner'

const query = gql`
  query BuilderBaseModels($id: ID!) {
    builder(guid: $id) {
      base_models {
        name
        price
        description
      }
    }
  }
`

const setBaseModelsMutation = gql`
  mutation SetBaseModels($builder_id: ID!, $base_models: [BaseModelInput]!) {
    setBaseModels(builder_id: $builder_id, base_models: $base_models) {
      base_models {
        name
        price
        description
      }
    }
  }
`

class BaseModelEditor extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editing: false,
      saving: false,
      models: props.models.map((model, id) => ({ ...model, id }))
    }

    this.handleAddClick = this.handleAddClick.bind(this)
    this.handleEditClick = () => this.setState({ editing: true })
    this.handleSaveClick = this.handleSaveClick.bind(this)
  }

  handleAddClick() {
    this.setState(state => ({
      models: [
        ...state.models,
        { name: '', price: 0, description: '', id: Date.now() }
      ]
    }))
  }

  handleSaveClick() {
    console.log(this.state.models)
    this.setState({
      editing: false,
      saving: true
    })
    const modelsToSave = this.state.models
      .map(m => ({
        name: m.name,
        price: parseFloat(m.price, 10),
        description: m.description
      }))
      .filter(({ name, price }) => name !== '' && !isNaN(price))

    console.log(this.props)

    this.props
      .setBaseModels(this.props.builderId, modelsToSave)
      .then(response => console.log(response), err => console.log(err.message))
      .then(() => this.setState({ saving: false }))
  }

  handleInput(id, type, value) {
    this.setState(state => {
      const model = state.models.find(model => model.id === id)
      if (model) {
        model[type] = value
      }
      return {
        models: state.models
      }
    })
  }

  handleRemove(id) {
    this.setState(state => ({
      models: state.models.filter(model => model.id !== id)
    }))
  }

  renderStatic() {
    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>name</th>
              <th className="text-right">price</th>
              <th>description</th>
            </tr>
          </thead>
          <tbody>
            {this.state.models.map(({ name, price, description, id }) => (
              <tr key={id}>
                <td>{name}</td>
                <td className="text-right">${price}</td>
                <td className="text-right">{description}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button onClick={this.handleEditClick}>edit</Button>
      </div>
    )
  }

  render() {
    if (this.state.saving) return <div>saving...</div>
    if (!this.state.editing) return this.renderStatic()

    return (
      <div>
        <table>
          <thead>
            <tr>
              <th>name</th>
              <th className="text-right">price</th>
              <th className="text-right">description</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.state.models.map(({ name, price, description, id }) => (
              <tr key={id}>
                <td style={{ width: '20%' }}>
                  <input
                    style={{ width: '100%' }}
                    value={name}
                    onChange={e => this.handleInput(id, 'name', e.target.value)}
                  />
                </td>
                <td className="text-right" style={{ width: '20%' }}>
                  $
                  <input
                    style={{ width: '80%' }}
                    value={price}
                    className="text-right"
                    onChange={e =>
                      this.handleInput(id, 'price', e.target.value)
                    }
                  />
                </td>
                <td className="text-right" style={{ width: '45%' }}>
                  <textarea
                    style={{ width: '100%' }}
                    value={description}
                    rows={10}
                    onChange={e =>
                      this.handleInput(id, 'description', e.target.value)
                    }
                  />
                </td>
                <td style={{ width: '15%' }}>
                  <Button onClick={() => this.handleRemove(id)}>remove</Button>
                </td>
              </tr>
            ))}
            <tr key="add-row">
              <td>
                <Button onClick={this.handleAddClick}>add</Button>
                <br />
                <Button onClick={this.handleSaveClick}>save</Button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

const WithBaseModels = graphql(query, {
  options: props => ({ variables: { id: props.builderId } })
})

const WithSetBaseModels = graphql(setBaseModelsMutation, {
  props: ({ mutate }) => ({
    setBaseModels: (builder_id, base_models) =>
      mutate({ variables: { builder_id, base_models } })
  })
})

export default compose(WithBaseModels, WithSetBaseModels)(props => (
  <WithLoadingSpinner {...props}>
    {props => (
      <BaseModelEditor
        builderId={props.builderId}
        models={props.data.builder.base_models}
        setBaseModels={props.setBaseModels}
      />
    )}
  </WithLoadingSpinner>
))
