import styled from 'styled-components'

const Button = styled.button`
  background: white;
  color: #333;
  border: 1px solid #999;
  border-radius: 2px;
  padding: 0.4rem;
  font-size: 0.9rem;
  letter-spacing: 0.03rem;

  box-shadow: inset 0px -2px 10px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.05s ease-in;

  &:hover {
    box-shadow: inset 0px 2px 10px rgba(0, 0, 0, 0.15);
    color: black;
    cursor: pointer;
  }

  ${props =>
    props.primary &&
    `
    &:hover {
      background: #00c74b;
    }
  `} ${props =>
  props.danger &&
  `
    color: red;

    &:hover {
      background: red;
      color: white;
    }
  `} ${props =>
  props.wide &&
  `
    width: 100%;
  `};
`

export default Button
