import React from 'react'
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'

class PriceDisplaySelector extends React.Component {
  render() {
    const { loading, error, builder } = this.props.data
    if (loading) return <div> loading... </div>
    if (error) return <div> error... </div>

    let { show_prices, show_only_total } = builder

    let noPrices = !show_prices
    let allPrices = show_prices && !show_only_total
    let onlyTotal = show_prices && show_only_total

    let update = ({ show_prices, show_only_total }) => {
      this.props
        .setPriceDisplay({
          builder_id: builder.guid,
          show_prices,
          show_only_total
        })
        .then(this.props.data.refetch)
    }

    return (
      <div>
        <b>Display:</b>
        <label>
          <input
            type="radio"
            checked={noPrices}
            onChange={() => {
              update({ show_prices: false, show_only_total: false })
            }}
          />
          no prices
        </label>
        <label>
          <input
            type="radio"
            checked={allPrices}
            onChange={() => {
              update({ show_prices: true, show_only_total: false })
            }}
          />
          all prices
        </label>
        <label>
          <input
            type="radio"
            checked={onlyTotal}
            onChange={() => {
              update({ show_prices: true, show_only_total: true })
            }}
          />
          only total
        </label>
      </div>
    )
  }
}

export default compose(
  graphql(
    gql`
      query UserHomePriceDisplaySelector($guid: ID!) {
        builder(guid: $guid) {
          guid
          show_prices
          show_only_total
        }
      }
    `,
    {
      options: props => ({
        variables: { guid: props.builderId }
      })
    }
  ),
  graphql(
    gql`
      mutation SetBuilderPriceDisplay($fields: SetBuilderPriceDisplayFields!) {
        setBuilderPriceDisplay(fields: $fields) {
          guid
          show_prices
          show_only_total
        }
      }
    `,
    {
      props: ({ mutate }) => {
        return {
          setPriceDisplay: fields => mutate({ variables: { fields } })
        }
      }
    }
  )
)(PriceDisplaySelector)
