import React from 'react'
import { graphql } from 'react-apollo'
import gql from 'graphql-tag'
import formatDate from 'date-fns/format'
import ReactToPrint from 'react-to-print'

import { WithLoadingSpinner } from '../../components/LoadingSpinner'
import { calculateTotal, formatter } from './helpers'

const query = gql`
  query UserSaveDetail($id: ID!) {
    user_save(id: $id) {
      name
      email
      phone
      show_prices
      contact_opt_in
      test
      image_url
      options
      created_at
      builder {
        show_prices
      }
    }
  }
`

class UserSavesDetail extends React.Component {
  constructor(props) {
    super(props)
    this.componentRef = React.createRef()
  }

  render() {
    return (
      <WithLoadingSpinner {...this.props}>
        {({ data }) => {
          const save = data.user_save
          const options = JSON.parse(save.options)
          const date = formatDate(save.created_at, 'MMM D')
          const time = formatDate(save.created_at, 'h:mma')
          const price = formatter.format(calculateTotal(options))

          return (
            <div>
              <h1>Cart Configuration</h1>
              <h2 className="h3">
                {date} at {time}
              </h2>
              <div
                className="Card island gutter"
                ref={el => (this.componentRef = el)}
              >
                <div className="cart-image">
                  {save.image_url && (
                    <img
                      style={{ width: 500, height: 350 }}
                      src={save.image_url}
                    />
                  )}
                </div>
                <div className="Card__left flex1">
                  <div>
                    <b>{save.name}</b>
                  </div>
                  <div>
                    <span>Phone:</span>{' '}
                    <b>{save.phone || 'Number not provided'}</b>
                  </div>
                  <a href={`mailto:${save.email}`}>{save.email}</a>

                  {save.contact_opt_in ? (
                    <div>Would like you to follow up.</div>
                  ) : (
                    <div>
                      Would <b>not</b> like you to follow up.
                    </div>
                  )}
                </div>

                <div className="Card__right flex1">
                  <ul>
                    {options.map((option, idx) => (
                      <li key={idx}>
                        <div>
                          {idx === 0 && option.brand_model ? (
                            <b>{`${option.brand_model} - ${option.group_label}`}</b>
                          ) : (
                            <b>{option.group_label || option.group}</b>
                          )}
                        </div>
                        <div>{option.option_label || option.option}</div>
                        {save.show_prices && <div>${option.price}</div>}
                      </li>
                    ))}
                  </ul>
                  <div>
                    {save.builder.show_prices && (
                      <span>
                        <b>Total:</b> {price}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <ReactToPrint
                trigger={() => <button href="#">Print Order</button>}
                content={() => this.componentRef}
              />
            </div>
          )
        }}
      </WithLoadingSpinner>
    )
  }
}

export default graphql(query, {
  options: props => ({ variables: { id: props.match.params.id } })
})(UserSavesDetail)
