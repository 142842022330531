import React from 'react'
import { compose, graphql } from 'react-apollo'
import gql from 'graphql-tag'

import Form from './Form'

const CART_MODELS = ['1', '2', '3', '4', '5', '6', '7']
const BASE_MODELS = [
  {
    name: '2017 Gas',
    price: 7000,
    description: 'Default Model'
  },
  {
    name: '2017 Electric',
    price: 6500,
    description: 'Default Model'
  }
]

class DealerEditor extends React.Component {
  handleSubmit = async fields => {
    const editing = this.props.id != null
    const creating = !editing

    if (creating) {
      try {
        const {
          data: { addUser: user }
        } = await this.props.addDealer({
          ...fields,
          password: 'password',
          // Override the id so it doesn't send the empty string
          id: undefined
        })

        // Active first model
        CART_MODELS.map(async cartModel => {
          const {
            data: { createBuilder }
          } = await this.props.createBuilder({
            user_id: user.id,
            cart_model_id: cartModel
          })

          this.props.setBaseModels(createBuilder.guid, BASE_MODELS)
        })

        window.alert('Added user!')
        // TODO: make this navigate without forcing the page to reload.
        window.location = `/dealers/${user.id}`
      } catch (e) {
        console.log(e)
      }
    } else {
      try {
        const {
          data: { updateUser: user }
        } = await this.props.updateDealer(fields)
        window.alert('updated user!')
        // TODO: make this navigate without forcing the page to reload.
        window.location = `/dealers/${user.id}`
      } catch (e) {
        console.log(e)
      }
    }
  }

  render() {
    const editing = this.props.id != null
    const creating = !editing

    if (creating) {
      return <Form key="new" onSubmit={this.handleSubmit} />
    }

    // This is all untested, just a sketch for now.
    const { loading, error, user } = this.props.data

    if (loading || error) {
      return <Form key="loading" onSubmit={() => {}} disabled />
    }

    // Remove __typename from to prevent it being passed in the mutation.
    const { __typename, ...initialValues } = user

    return (
      <Form
        key={user.id}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
      />
    )
  }
}

const addDealerMutation = gql`
  mutation AddDealer($fields: AddUserFields!) {
    addUser(fields: $fields) {
      id
      name
      email
      cc_emails
      allowed_domain
      business_name
      location
      active
    }
  }
`

const updateDealerMutation = gql`
  mutation UpdateDealer($fields: UpdateUserFields!) {
    updateUser(fields: $fields) {
      id
      name
      email
      cc_emails
      allowed_domain
      business_name
      location
      active
    }
  }
`

const editDealerQuery = gql`
  query DealerFormEditDetails($id: ID!) {
    user(id: $id) {
      id
      name
      email
      cc_emails
      allowed_domain
      business_name
      location
      active
    }
  }
`

export default compose(
  graphql(addDealerMutation, {
    props: ({ mutate }) => {
      return {
        addDealer: fields => mutate({ variables: { fields } })
      }
    }
  }),
  graphql(updateDealerMutation, {
    props: ({ mutate }) => {
      return {
        updateDealer: fields => mutate({ variables: { fields } })
      }
    }
  }),
  graphql(editDealerQuery, {
    skip: ownProps => ownProps.id == null
  }),
  graphql(
    gql`
      mutation CreateBuilder($fields: CreateBuilderFields!) {
        createBuilder(fields: $fields) {
          guid
          cart_model {
            id
          }
        }
      }
    `,
    {
      props: ({ mutate }) => {
        return {
          createBuilder: ({ user_id, cart_model_id }) =>
            mutate({ variables: { fields: { user_id, cart_model_id } } })
        }
      }
    }
  ),
  graphql(
    gql`
      mutation SetBaseModels(
        $builder_id: ID!
        $base_models: [BaseModelInput]!
      ) {
        setBaseModels(builder_id: $builder_id, base_models: $base_models) {
          base_models {
            name
            price
            description
          }
        }
      }
    `,
    {
      props: ({ mutate }) => {
        return {
          setBaseModels: (builder_id, base_models) =>
            mutate({ variables: { builder_id, base_models } })
        }
      }
    }
  )
)(DealerEditor)
