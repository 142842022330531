const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
})

const calculateTotal = options => {
  return options.reduce(function(prev, cur) {
    return prev + cur['price']
  }, 0)
}

export { formatter, calculateTotal }
